import * as React from 'react';
import { ScrollView } from 'react-native';

//import '@expo/match-media';
import Select from 'react-select'
import { FixedSizeList as List } from "react-window";
import Markdown from 'react-native-markdown-display'

import {ActivityIndicator, Button, Provider, Text, DefaultTheme, DarkTheme, Title, Subheading, Headline, Caption, Paragraph} from 'react-native-paper';



import {styles, pickerStyles, Separator} from './Styles'
import {params} from './Params'
import {strings} from './Strings'




class MenuList extends React.Component {
  render() {
    const height=35
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * height;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={height}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <Text style={style} >{children[index]}</Text>}
      </List>
    );
  }
}






export const HomeScreen = ({language}) => {


    const [isLoading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [selectedPLZ, setSelectedPLZ] = React.useState();
    const [selectedItemTypes, setSelectedItemTypes] = React.useState([]);

    const [plzValues, setPLZValues] = React.useState([]);
    const [possibleItemTypes, setPossibleItemTypes] = React.useState([]);

    const [isFirstTime, setFirstTime] = React.useState(true);

    
    
    React.useEffect( () => {
      var plzs = (params.plz).sort().map((v: string) => {return { value: v.substring(0,4), label: v }})
      var items = (params.items).sort().map((v: string) => {return { value: v, label: v }})
      
      setPLZValues(plzs)
      setPossibleItemTypes(items)

      setSelectedItemTypes(items)
      setSelectedPLZ({value: '8193', label: '8193 Eglisau'})

      setFirstTime(true)

      console.log('Fetched')
    
    }, [])
    
    const onButtonClicked = ()  => {

      if (selectedPLZ==null || selectedItemTypes==null) return
      let acceptedItems = selectedItemTypes.map( (t) => t.value ).join(',')
      let plzVal = selectedPLZ.value
      console.log(plzVal)
      console.log(acceptedItems)

      setLoading(true)
      
      
      fetch(`https://intense-spire-85479.herokuapp.com/find_charities?plz=${plzVal}&items=${acceptedItems}`, {
          method: 'GET',
          headers: {'Accept': 'application/json'}
        })
        .then((response) => {
          return response.json() 
          }) 
        .then((json) => {
          setData(json)
          setFirstTime(false)
        }) 
        .catch((error) => console.log('E '+error))
        .finally(() => setLoading(false));
      
    }
    
    return (
      <Provider>
          <Paragraph style={styles.body}>
            {strings[language].smallIntro}
          </Paragraph>
          
          <Text style={styles.smallBody}>{strings[language].plzLabel}</Text>
          <Select components={{ MenuList }} options= {plzValues} value={selectedPLZ} styles={pickerStyles} onChange = { (item:string) => setSelectedPLZ(item) } defaultMenuIsOpen={false} />
          <Separator/>
          
          <Text style={styles.smallBody}>{strings[language].itemTypesLabel} </Text>
          <Select  options= {possibleItemTypes} isMulti={true} value={selectedItemTypes} styles={pickerStyles}  onChange = { (items:string[]) => setSelectedItemTypes(items) } />
          <Separator />

          <Button mode='outlined' onPress={(onButtonClicked)} disabled={selectedItemTypes.length==0} >{strings[language].buttonLabel}</Button>
          {isLoading ? <ActivityIndicator size={80} />:<Text></Text>}

          <ScrollView >
            <Markdown style={styles} >
              {
                data.length>0 || isFirstTime ? 
                data.map( item => {
                let distance = (item.distance).toFixed(2)
                return `1.  _${distance} km_, [${item.name}](${item.website}), ${item.address}, Accepts **${item.accepted_items}** \n&nbsp;&nbsp;&nbsp;&nbsp;`
              }).join('\n')
              : strings[language].notFound
              } 
            </Markdown>
          </ScrollView>
      </Provider>
    );
}