import * as React from 'react';
import { View, ScrollView} from 'react-native';
import Markdown from 'react-native-markdown-display'
import {styles} from './Styles'
import {Button, TextInput} from 'react-native-paper';
import Toast from 'react-native-fast-toast/lib/typescript/toast';
import {strings} from './Strings'

const text = { EN: `

# About Goods2Give
It happens more than often that we have spare items or canned food that we no longer use, and on the other hand don't think at all they should be thrown away. I have always had difficulty finding the right organisation to donate spare items, which is also closeby. 
Therefore, I decided to create a database of all places in Switzerland where you can donate (canned) food, household appliances, furniture, and other non-monetary items.
Please help complete this database by going to [https://github.com/aghasemi/where2give](https://github.com/aghasemi/where2give) and adding new organisations to the file _charities.tsv_. You may also send an e-mail to _alireza.ghasemi@bluewin.ch_ or use the feedback box below.
## Disclaimer
I am in no way, shape or form related to any of the organisations listed here, neither do I endorse or gauarantee the legitimacy of any of them. This service is just to help find Swiss charitable organisations accepting non-monetary donations. Please do your due diligence before making donations. 

## Contribute
In case you know a charitable organisation that accepts non-monetary donations but is not listed by Goods2Give, I really appreciate if you help me by mentionign it below. Any piece of information helps.
`,
  DE: `

# Über Goods2Give
Es kommt mehr als oft vor, dass wir Ersatzartikel oder Konserven haben, die wir nicht mehr verwenden, und andererseits überhaupt nicht denken, dass sie weggeworfen werden sollten. Ich hatte immer Schwierigkeiten, die richtige Organisation, um Ersatzartikel zu spenden, die auch in der Nähe liegt, zu finden.
Deshalb habe ich beschlossen, eine Datenbank aller Orte in der Schweiz zu schaffen, wo Sie können (aus der Dose) spenden Lebensmittel, Haushaltswäre, Möbel und andere nicht-monetären Artikel.
Bitte helfen Sie, um diese Datenbank zu komplettieren. Shicken Sie mir Informarionen über übrige Hilfsorganisationen, die in der List nicht anwesend sind, durch  eine e-mail nach _alireza.ghasemi@bluewin.ch_ shicken, oder das Formular in dieser Seite zu ergänzen.

## Haftungsausschluss
Ich bin in in keiner Form mit die hier aufgeführte Organisationen verwandt, noch unterstütze oder garantiere ich ihre Legitimität. Dieser Service soll nur dazu beitragen, Schweizer Hilfsorganisationen, die nicht-monetäre Spenden annehmen, zu finden. Bitte führen Sie Ihre Due Diligence durch, bevor Sie spenden.

## Beitragen
Falls Sie eine Hilfsorganisation kennen, die nicht-monetäre Spenden annehmen, aber nicht in Goods2Give existiert, bitte hilfen Sie mir durch sie hier erwähnen.

`}


export const AboutScreen = ({language}) => {
  const [charityInfo, setCharityInfo] = React.useState("")
  const onSend = async () => {
    fetch(`https://intense-spire-85479.herokuapp.com/plz?msg=${charityInfo}`, {
      method: 'GET',
      headers: {'Accept': 'application/json'}
    }).then((response) => {
      console.log("Feedback sent!") 
      toast.show(strings[language].thanks)
    })

  } 

  return (
      <ScrollView >
        <Markdown style={styles}> 
          {text[language]}
        </Markdown>
        <TextInput placeholder={strings[language].charityInfoPlaceholder} numberOfLines={2} value={charityInfo} onChangeText={ text => setCharityInfo(text) } >

        </TextInput>
        <Button mode="outlined" onPress={onSend} disabled={charityInfo.length==0}>
          Send
        </Button>
        <Markdown>  &copy; [Alireza Ghasemi](https://aghasemi.github.io), 2021. </Markdown>
      </ScrollView>

    );
  }
