import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import * as React from 'react';

import {HomeScreen} from './HomeScreen'
import {AboutScreen} from './AboutScreen'

import {Text} from 'react-native-paper';

import {styles} from './Styles'


const Tab = createBottomTabNavigator();

export function TabManager(props) {
  return (
    <Tab.Navigator screenOptions={({ route }) => ({
      tabBarIcon: ({ focused }) => {
        return (
          <Text style={focused ? styles.body : styles.smallBody}> {route.name.replace('Goods2Give: ','')} </Text>);
      },})} tabBarOptions={{showLabel: false}}  >
      <Tab.Screen name="Goods2Give: Home"  children={() => <HomeScreen  language={props.language}  />} />
      <Tab.Screen name="Goods2Give: About" children={() => <AboutScreen language={props.language}  />} />
    </Tab.Navigator>
  );
}
