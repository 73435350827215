export const params = {
    "items": [
        "Clothing",
        "Household Items",
        "Food"
    ],
    "plz": [
        "5000 Aarau",
        "5004 Aarau",
        "5017 Barmelweid",
        "5018 Erlinsbach",
        "5022 Rombach",
        "5023 Biberstein",
        "5024 Küttigen",
        "5025 Asp",
        "5026 Densbüren",
        "5032 Aarau Rohr",
        "5033 Buchs AG",
        "5034 Suhr",
        "5035 Unterentfelden",
        "5036 Oberentfelden",
        "5037 Muhen",
        "5042 Hirschthal",
        "5722 Gränichen",
        "5300 Turgi",
        "5301 Siggenthal Station",
        "5303 Würenlingen",
        "5400 Baden",
        "5404 Baden",
        "5405 Dättwil AG",
        "5406 Rütihof",
        "5408 Ennetbaden",
        "5412 Vogelsang AG",
        "5412 Gebenstorf",
        "5413 Birmenstorf AG",
        "5415 Nussbaumen AG",
        "5415 Hertenstein AG",
        "5415 Rieden AG",
        "5416 Kirchdorf AG",
        "5417 Untersiggenthal",
        "5420 Ehrendingen",
        "5423 Freienwil",
        "5430 Wettingen",
        "5432 Neuenhof",
        "5436 Würenlos",
        "5442 Fislisbach",
        "5443 Niederrohrdorf",
        "5444 Künten",
        "5452 Oberrohrdorf",
        "5453 Remetschwil",
        "5454 Bellikon",
        "5506 Mägenwil",
        "5507 Mellingen",
        "5512 Wohlenschwil",
        "5608 Stetten AG",
        "8109 Kloster Fahr",
        "8956 Killwangen",
        "8957 Spreitenbach",
        "8962 Bergdietikon",
        "5445 Eggenwil",
        "5522 Tägerig",
        "5524 Niederwil AG",
        "5524 Nesselnbach",
        "5525 Fischbach-Göslikon",
        "5605 Dottikon",
        "5607 Hägglingen",
        "5610 Wohlen AG",
        "5611 Anglikon",
        "5612 Villmergen",
        "5613 Hilfikon",
        "5614 Sarmenstorf",
        "5619 Uezwil",
        "5619 Büttikon AG",
        "5620 Bremgarten AG",
        "5621 Zufikon",
        "5626 Hermetschwil-Staffeln",
        "8905 Arni AG",
        "8905 Islisberg",
        "8916 Jonen",
        "8917 Oberlunkhofen",
        "8918 Unterlunkhofen",
        "8964 Rudolfstetten",
        "8965 Berikon",
        "8966 Oberwil-Lieli",
        "8967 Widen",
        "5076 Bözen",
        "5077 Elfingen",
        "5078 Effingen",
        "5105 Auenstein",
        "5106 Veltheim AG",
        "5107 Schinznach Dorf",
        "5108 Oberflachs",
        "5112 Thalheim AG",
        "5116 Schinznach Bad",
        "5200 Brugg AG",
        "5210 Windisch",
        "5212 Hausen AG",
        "5213 Villnachern",
        "5222 Umiken",
        "5223 Riniken",
        "5225 Bözberg",
        "5233 Stilli",
        "5234 Villigen",
        "5235 Rüfenach AG",
        "5236 Remigen",
        "5237 Mönthal",
        "5242 Birr",
        "5242 Lupfig",
        "5243 Mülligen",
        "5244 Birrhard",
        "5245 Habsburg",
        "5246 Scherz",
        "5318 Mandach",
        "5040 Schöftland",
        "5043 Holziken",
        "5044 Schlossrued",
        "5046 Walde AG",
        "5046 Schmiedrued",
        "5708 Birrwil",
        "5712 Beinwil am See",
        "5723 Teufenthal AG",
        "5724 Dürrenäsch",
        "5725 Leutwil",
        "5726 Unterkulm",
        "5727 Oberkulm",
        "5728 Gontenschwil",
        "5732 Zetzwil",
        "5733 Leimbach AG",
        "5734 Reinach AG",
        "5736 Burg AG",
        "5737 Menziken",
        "4333 Münchwilen AG",
        "4334 Sisseln AG",
        "5027 Herznach",
        "5028 Ueken",
        "5062 Oberhof",
        "5063 Wölflinswil",
        "5064 Wittnau",
        "5070 Frick",
        "5072 Oeschgen",
        "5073 Gipf-Oberfrick",
        "5074 Eiken",
        "5075 Hornussen",
        "5079 Zeihen",
        "5080 Laufenburg",
        "5082 Kaisten",
        "5083 Ittenthal",
        "5084 Rheinsulz",
        "5085 Sulz AG",
        "5272 Gansingen",
        "5273 Oberhofen AG",
        "5274 Mettau",
        "5275 Etzgen",
        "5276 Wil AG",
        "5277 Hottwil",
        "5326 Schwaderloch",
        "5102 Rupperswil",
        "5103 Wildegg",
        "5103 Möriken AG",
        "5113 Holderbank AG",
        "5502 Hunzenschwil",
        "5503 Schafisheim",
        "5504 Othmarsingen",
        "5505 Brunegg",
        "5600 Lenzburg",
        "5600 Ammerswil AG",
        "5603 Staufen",
        "5604 Hendschiken",
        "5606 Dintikon",
        "5615 Fahrwangen",
        "5616 Meisterschwanden",
        "5617 Tennwil",
        "5702 Niederlenz",
        "5703 Seon",
        "5704 Egliswil",
        "5705 Hallwil",
        "5706 Boniswil",
        "5707 Seengen",
        "5618 Bettwil",
        "5622 Waltenschwil",
        "5623 Boswil",
        "5624 Bünzen",
        "5624 Waldhäusern AG",
        "5625 Kallern",
        "5627 Besenbüren",
        "5628 Aristau",
        "5630 Muri AG",
        "5632 Buttwil",
        "5634 Merenschwand",
        "5636 Benzenschwil",
        "5637 Beinwil (Freiamt)",
        "5637 Geltwil",
        "5642 Mühlau",
        "5643 Meienberg",
        "5643 Alikon",
        "5643 Sins",
        "5643 Sins",
        "5644 Auw",
        "5645 Aettenschwil",
        "5645 Fenkrieden",
        "5646 Abtwil AG",
        "5647 Oberrüti",
        "6042 Dietwil",
        "8919 Rottenschwil",
        "4303 Kaiseraugst",
        "4305 Olsberg",
        "4310 Rheinfelden",
        "4312 Magden",
        "4313 Möhlin",
        "4314 Zeiningen",
        "4315 Zuzgen",
        "4316 Hellikon",
        "4317 Wegenstetten",
        "4322 Mumpf",
        "4323 Wallbach",
        "4324 Obermumpf",
        "4325 Schupfart",
        "4332 Stein AG",
        "4663 Aarburg",
        "4665 Oftringen",
        "4800 Zofingen",
        "4802 Strengelbach",
        "4803 Vordemwald",
        "4805 Brittnau",
        "4812 Mühlethal",
        "4813 Uerkheim",
        "4814 Bottenwil",
        "4852 Rothrist",
        "4853 Murgenthal",
        "4853 Riken AG",
        "4853 Murgenthal",
        "4856 Glashütten",
        "5053 Wittwil",
        "5053 Staffelbach",
        "5054 Moosleerau",
        "5054 Kirchleerau",
        "5056 Attelwil",
        "5057 Reitnau",
        "5058 Wiliberg",
        "5742 Kölliken",
        "5745 Safenwil",
        "5304 Endingen",
        "5305 Unterendingen",
        "5306 Tegerfelden",
        "5312 Döttingen",
        "5313 Klingnau",
        "5314 Kleindöttingen",
        "5315 Böttstein",
        "5316 Leuggern",
        "5317 Hettenschwil",
        "5322 Koblenz",
        "5323 Rietheim",
        "5324 Full-Reuenthal",
        "5325 Leibstadt",
        "5330 Bad Zurzach",
        "5332 Rekingen AG",
        "5333 Baldingen",
        "5334 Böbikon",
        "5425 Schneisingen",
        "5426 Lengnau AG",
        "5462 Siglistorf",
        "5463 Wislikofen",
        "5464 Rümikon AG",
        "5465 Mellikon",
        "5466 Kaiserstuhl AG",
        "5467 Fisibach",
        "9050 Appenzell Meistersrüte",
        "9050 Appenzell Enggenhütten",
        "9050 Appenzell Steinegg",
        "9050 Appenzell Eggerstanden",
        "9050 Appenzell",
        "9050 Appenzell Schlatt",
        "9054 Haslen AI",
        "9057 Schwende",
        "9057 Weissbad",
        "9057 Wasserauen",
        "9058 Brülisau",
        "9108 Gonten",
        "9108 Gontenbad",
        "9108 Jakobsbad",
        "9413 Oberegg",
        "9413 Oberegg",
        "9442 Büriswilen",
        "9063 Stein AR",
        "9064 Hundwil",
        "9100 Herisau",
        "9103 Schwellbrunn",
        "9104 Waldstatt",
        "9105 Schönengrund",
        "9107 Urnäsch",
        "9112 Schachen b. Herisau",
        "9037 Speicherschwendi",
        "9042 Speicher",
        "9043 Trogen",
        "9052 Niederteufen",
        "9053 Teufen AR",
        "9055 Bühler",
        "9056 Gais",
        "9062 Lustmühle",
        "9035 Grub AR",
        "9038 Rehetobel",
        "9044 Wald AR",
        "9405 Wienacht-Tobel",
        "9410 Heiden",
        "9411 Reute AR",
        "9411 Schachen b. Reute",
        "9426 Lutzenberg",
        "9427 Wolfhalden",
        "9428 Walzenhausen",
        "2333 La Ferrière",
        "2345 Le Cerneux-Veusil",
        "2515 Prêles",
        "2516 Lamboing",
        "2517 Diesse",
        "2518 Nods",
        "2520 La Neuveville",
        "2534 Orvin",
        "2534 Les Prés-d'Orvin",
        "2535 Frinvillier",
        "2536 Plagne",
        "2537 Vauffelin",
        "2538 Romont BE",
        "2603 Péry",
        "2604 La Heutte",
        "2605 Sonceboz-Sombeval",
        "2606 Corgémont",
        "2607 Cortébert",
        "2608 Montagne-de-Courtelary",
        "2608 Courtelary",
        "2610 Les Pontins",
        "2610 Mont-Crosin",
        "2610 St-Imier",
        "2610 Mont-Soleil",
        "2612 Cormoret",
        "2613 Villeret",
        "2615 Montagne-de-Sonvilier",
        "2615 Sonvilier",
        "2616 Renan BE",
        "2710 Tavannes",
        "2712 Le Fuet",
        "2713 Bellelay",
        "2715 Châtelat",
        "2715 Monible",
        "2716 Sornetan",
        "2717 Fornet-Dessous",
        "2717 Rebévelier",
        "2720 La Tanne",
        "2720 Tramelan",
        "2722 Les Reussilles",
        "2723 Mont-Tramelan",
        "2732 Saules BE",
        "2732 Loveresse",
        "2732 Reconvilier",
        "2732 Saicourt",
        "2733 Pontenet",
        "2735 Champoz",
        "2735 Bévilard",
        "2735 Malleray",
        "2736 Sorvilier",
        "2738 Court",
        "2740 Moutier",
        "2742 Perrefitte",
        "2743 Eschert",
        "2744 Belprahon",
        "2745 Grandval",
        "2746 Crémines",
        "2747 Corcelles BE",
        "2747 Seehof",
        "2748 Les Ecorcheresses",
        "2748 Souboz",
        "2762 Roches BE",
        "2827 Schelten",
        "2502 Biel/Bienne",
        "2503 Biel/Bienne",
        "2504 Biel/Bienne",
        "2505 Biel/Bienne",
        "2512 Tüscherz-Alfermée",
        "2513 Twann",
        "2514 Ligerz",
        "2532 Macolin",
        "2533 Evilard",
        "2542 Pieterlen",
        "2543 Lengnau BE",
        "2552 Orpund",
        "2553 Safnern",
        "2554 Meinisberg",
        "2555 Brügg BE",
        "2556 Schwadernau",
        "2556 Scheuren",
        "2558 Aegerten",
        "2560 Nidau",
        "2562 Port",
        "2563 Ipsach",
        "2564 Bellmund",
        "2572 Sutz",
        "2572 Mörigen",
        "2557 Studen BE",
        "2565 Jens",
        "2575 Hagneck",
        "2575 Täuffelen",
        "2575 Gerolfingen",
        "2576 Lüscherz",
        "2577 Finsterhennen",
        "2577 Siselen BE",
        "3035 Frieswil",
        "3036 Detligen",
        "3053 Lätti",
        "3054 Schüpfen",
        "3225 Müntschemier",
        "3226 Treiten",
        "3232 Ins",
        "3233 Tschugg",
        "3234 Vinelz",
        "3235 Erlach",
        "3236 Gampelen",
        "3237 Brüttelen",
        "3238 Gals",
        "3250 Lyss",
        "3251 Wengi b. Büren",
        "3251 Ruppoldsried",
        "3252 Worben",
        "3255 Rapperswil BE",
        "3256 Dieterswil",
        "3256 Seewil",
        "3256 Bangerten b. Dieterswil",
        "3257 Ammerzwil BE",
        "3257 Grossaffoltern",
        "3262 Suberg",
        "3263 Büetigen",
        "3264 Diessbach b. Büren",
        "3266 Wiler b. Seedorf",
        "3267 Seedorf BE",
        "3268 Lobsigen",
        "3270 Aarberg",
        "3271 Radelfingen b. Aarberg",
        "3272 Walperswil",
        "3272 Epsach",
        "3273 Kappelen",
        "3274 Merzligen",
        "3274 Bühl b. Aarberg",
        "3274 Hermrigen",
        "3282 Bargen BE",
        "3283 Kallnach",
        "3283 Niederried b. Kallnach",
        "3292 Busswil BE",
        "3293 Dotzigen",
        "3294 Büren an der Aare",
        "3294 Meienried",
        "3295 Rüti b. Büren",
        "3296 Arch",
        "3297 Leuzigen",
        "3298 Oberwil b. Büren",
        "3360 Herzogenbuchsee",
        "3362 Niederönz",
        "3363 Oberönz",
        "3365 Grasswil",
        "3365 Seeberg",
        "3366 Bollodingen",
        "3366 Bettenhausen",
        "3367 Ochlenberg",
        "3367 Thörigen",
        "3368 Bleienbach",
        "3372 Wanzwil",
        "3373 Heimenhausen",
        "3373 Röthenbach Herzogenbuchsee",
        "3374 Wangenried",
        "3375 Inkwil",
        "3376 Graben",
        "3376 Berken",
        "3377 Walliswil b. Wangen",
        "3380 Wangen an der Aare",
        "3380 Walliswil b. Niederbipp",
        "3464 Schmidigen-Mühleweg",
        "3475 Hermiswil",
        "3475 Riedtwil",
        "3476 Oschwand",
        "4536 Attiswil",
        "4537 Wiedlisbach",
        "4538 Oberbipp",
        "4539 Farnern",
        "4539 Rumisberg",
        "4704 Niederbipp",
        "4704 Wolfisberg",
        "4900 Langenthal",
        "4911 Schwarzhäusern",
        "4912 Aarwangen",
        "4913 Bannwil",
        "4914 Roggwil BE",
        "4916 Untersteckholz",
        "4917 Busswil b. Melchnau",
        "4917 Melchnau",
        "4919 Reisiswil",
        "4922 Bützberg",
        "4922 Thunstetten",
        "4923 Wynau",
        "4924 Obersteckholz",
        "4932 Lotzwil",
        "4932 Gutenburg",
        "4933 Rütschelen",
        "4934 Madiswil",
        "4935 Leimiswil",
        "4936 Kleindietwil",
        "4937 Ursenbach",
        "4938 Rohrbach",
        "4938 Rohrbachgraben",
        "4942 Walterswil BE",
        "4943 Oeschenbach",
        "4944 Auswil",
        "4950 Huttwil",
        "4952 Eriswil",
        "4953 Schwarzenbach (Huttwil)",
        "4954 Wyssachen",
        "4955 Gondiswil",
        "3309 Kernenried",
        "3315 Bätterkinden",
        "3315 Kräiligen",
        "3324 Hindelbank",
        "3324 Mötschwil",
        "3325 Hettiswil b. Hindelbank",
        "3326 Krauchthal",
        "3400 Burgdorf",
        "3412 Heimiswil",
        "3413 Kaltacker",
        "3414 Oberburg",
        "3415 Schafhausen im Emmental",
        "3415 Hasle b. Burgdorf",
        "3415 Rüegsauschachen",
        "3416 Affoltern im Emmental",
        "3417 Rüegsau",
        "3418 Rüegsbach",
        "3419 Biembach im Emmental",
        "3421 Lyssach",
        "3421 Rüti b. Lyssach",
        "3421 Lyssach",
        "3422 Rüdtligen",
        "3422 Kirchberg BE",
        "3422 Alchenflüh",
        "3423 Ersigen",
        "3424 Niederösch",
        "3424 Oberösch",
        "3425 Willadingen",
        "3425 Koppigen",
        "3426 Aefligen",
        "3427 Utzenstorf",
        "3428 Wiler b. Utzenstorf",
        "3429 Höchstetten",
        "3429 Hellsau",
        "3432 Lützelflüh-Goldbach",
        "3433 Schwanden im Emmental",
        "3435 Ramsei",
        "3436 Zollbrück",
        "3437 Rüderswil",
        "3438 Lauperswil",
        "3439 Ranflüh",
        "3452 Grünenmatt",
        "3453 Heimisbach",
        "3454 Sumiswald",
        "3455 Grünen",
        "3456 Trachselwald",
        "3457 Wasen im Emmental",
        "3462 Weier im Emmental",
        "3463 Häusernmoos im Emmental",
        "3465 Dürrenroth",
        "3472 Rumendingen",
        "3472 Wynigen",
        "3473 Alchenstorf",
        "3474 Rüedisbach",
        "3534 Signau",
        "3535 Schüpbach",
        "3536 Aeschau",
        "3537 Eggiwil",
        "3538 Röthenbach im Emmental",
        "3543 Emmenmatt",
        "3550 Langnau im Emmental",
        "3551 Oberfrittenbach",
        "3552 Bärau",
        "3553 Gohl",
        "3555 Trubschachen",
        "3556 Trub",
        "3557 Fankhaus (Trub)",
        "3618 Süderen",
        "4564 Zielebach",
        "6197 Schangnau",
        "1595 Clavaleyres",
        "1738 Sangernboden",
        "1797 Münchenwiler",
        "3004 Bern",
        "3005 Bern",
        "3006 Bern",
        "3007 Bern",
        "3008 Bern",
        "3010 Bern",
        "3011 Bern",
        "3011 Bern",
        "3012 Bern",
        "3013 Bern",
        "3014 Bern",
        "3015 Bern",
        "3018 Bern",
        "3019 Bern",
        "3020 Bern",
        "3027 Bern",
        "3032 Hinterkappelen",
        "3033 Wohlen b. Bern",
        "3034 Murzelen",
        "3037 Herrenschwanden",
        "3038 Kirchlindach",
        "3042 Ortschwaben",
        "3043 Uettligen",
        "3044 Innerberg",
        "3045 Meikirch",
        "3046 Wahlendorf",
        "3047 Bremgarten b. Bern",
        "3048 Worblaufen",
        "3049 Säriswil",
        "3052 Zollikofen",
        "3053 Münchenbuchsee",
        "3053 Wiggiswil",
        "3053 Deisswil b. Münchenbuchsee",
        "3053 Diemerswil",
        "3063 Ittigen",
        "3065 Bolligen",
        "3066 Stettlen",
        "3067 Boll",
        "3068 Utzigen",
        "3072 Ostermundigen",
        "3073 Gümligen",
        "3074 Muri b. Bern",
        "3075 Rüfenacht BE",
        "3075 Vielbringen b. Worb",
        "3076 Worb",
        "3077 Enggistein",
        "3078 Richigen",
        "3082 Schlosswil",
        "3083 Trimstein",
        "3084 Wabern",
        "3086 Zimmerwald",
        "3086 Englisberg",
        "3087 Niedermuhlern",
        "3088 Rüeggisberg",
        "3088 Oberbütschel",
        "3089 Hinterfultigen",
        "3095 Spiegel b. Bern",
        "3096 Oberbalm",
        "3097 Liebefeld",
        "3098 Köniz",
        "3098 Schliern b. Köniz",
        "3099 Rüti b. Riggisberg",
        "3110 Münsingen",
        "3111 Tägertschi",
        "3112 Allmendingen b. Bern",
        "3113 Rubigen",
        "3114 Wichtrach",
        "3115 Gerzensee",
        "3116 Kirchdorf BE",
        "3116 Noflen BE",
        "3116 Mühledorf BE",
        "3122 Kehrsatz",
        "3123 Belp",
        "3124 Belpberg",
        "3125 Toffen",
        "3126 Gelterfingen",
        "3126 Kaufdorf",
        "3127 Lohnstorf",
        "3127 Mühlethurnen",
        "3128 Kirchenthurnen",
        "3128 Rümligen",
        "3132 Riggisberg",
        "3144 Gasel",
        "3145 Niederscherli",
        "3147 Mittelhäusern",
        "3148 Lanzenhäusern",
        "3150 Schwarzenburg",
        "3152 Mamishaus",
        "3153 Rüschegg Gambach",
        "3154 Rüschegg Heubach",
        "3155 Helgisried-Rohrbach",
        "3156 Riffenmatt",
        "3157 Milken",
        "3158 Guggisberg",
        "3159 Riedstätt",
        "3172 Niederwangen b. Bern",
        "3173 Oberwangen b. Bern",
        "3174 Thörishaus",
        "3176 Neuenegg",
        "3177 Laupen BE",
        "3179 Kriechenwil",
        "3183 Albligen",
        "3202 Frauenkappelen",
        "3203 Mühleberg",
        "3204 Rosshäusern",
        "3205 Gümmenen",
        "3206 Gammen",
        "3206 Ferenbalm",
        "3206 Rizenbach",
        "3206 Biberen",
        "3207 Wileroltigen",
        "3207 Golaten",
        "3208 Gurbrü",
        "3302 Moosseedorf",
        "3303 Zuzwil BE",
        "3303 Jegenstorf",
        "3303 Münchringen",
        "3303 Ballmoos",
        "3305 Iffwil",
        "3305 Scheunen",
        "3306 Etzelkofen",
        "3308 Grafenried",
        "3309 Zauggenried",
        "3312 Fraubrunnen",
        "3313 Büren zum Hof",
        "3314 Schalunen",
        "3317 Mülchi",
        "3317 Limpach",
        "3322 Urtenen-Schönbühl",
        "3322 Mattstetten",
        "3323 Bäriswil BE",
        "3434 Obergoldbach",
        "3434 Landiswil",
        "3503 Gysenstein",
        "3504 Niederhünigen",
        "3504 Oberhünigen",
        "3506 Grosshöchstetten",
        "3507 Biglen",
        "3508 Arni BE",
        "3510 Häutligen",
        "3510 Freimettigen",
        "3510 Konolfingen",
        "3512 Walkringen",
        "3513 Bigenthal",
        "3531 Oberthal",
        "3532 Mirchel",
        "3532 Zäziwil",
        "3533 Bowil",
        "3629 Oppligen",
        "3629 Jaberg",
        "3629 Kiesen",
        "3671 Herbligen",
        "3671 Brenzikofen",
        "3672 Aeschlen b. Oberdiessbach",
        "3672 Oberdiessbach",
        "3673 Linden",
        "3674 Bleiken b. Oberdiessbach",
        "3600 Thun",
        "3603 Thun",
        "3604 Thun",
        "3608 Thun",
        "3612 Steffisburg",
        "3613 Steffisburg",
        "3614 Unterlangenegg",
        "3615 Heimenschwand",
        "3616 Schwarzenegg",
        "3617 Fahrni b. Thun",
        "3619 Eriz",
        "3619 Innereriz",
        "3622 Homberg b. Thun",
        "3623 Buchen BE",
        "3623 Horrenbach",
        "3623 Teuffenthal b. Thun",
        "3624 Schwendibach",
        "3624 Goldiwil (Thun)",
        "3625 Heiligenschwendi",
        "3626 Hünibach",
        "3627 Heimberg",
        "3628 Uttigen",
        "3631 Höfen b. Thun",
        "3632 Niederstocken",
        "3632 Oberstocken",
        "3633 Amsoldingen",
        "3634 Thierachern",
        "3635 Uebeschi",
        "3636 Forst b. Längenbühl",
        "3636 Längenbühl",
        "3638 Pohlern",
        "3638 Blumenstein",
        "3645 Zwieselberg",
        "3647 Reutigen",
        "3652 Hilterfingen",
        "3653 Oberhofen am Thunersee",
        "3654 Gunten",
        "3655 Sigriswil",
        "3656 Tschingel ob Gunten",
        "3656 Ringoldswil",
        "3656 Aeschlen ob Gunten",
        "3657 Schwanden (Sigriswil)",
        "3658 Merligen",
        "3661 Uetendorf",
        "3662 Seftigen",
        "3663 Gurzelen",
        "3664 Burgistein",
        "3665 Wattenwil",
        "1657 Abländschen",
        "3766 Boltigen",
        "3770 Zweisimmen",
        "3771 Blankenburg",
        "3772 St. Stephan",
        "3773 Matten (St. Stephan)",
        "3775 Lenk im Simmental",
        "3776 Oeschseite",
        "3777 Saanenmöser",
        "3778 Schönried",
        "3780 Gstaad",
        "3781 Turbach",
        "3782 Lauenen b. Gstaad",
        "3783 Grund b. Gstaad",
        "3784 Feutersoey",
        "3785 Gsteig b. Gstaad",
        "3792 Saanen",
        "3645 Gwatt (Thun)",
        "3646 Einigen",
        "3700 Spiez",
        "3702 Hondrich",
        "3703 Aeschiried",
        "3703 Aeschi b. Spiez",
        "3704 Krattigen",
        "3705 Faulensee",
        "3711 Emdthal",
        "3711 Mülenen",
        "3713 Reichenbach im Kandertal",
        "3714 Frutigen",
        "3714 Wengi b. Frutigen",
        "3715 Adelboden",
        "3716 Kandergrund",
        "3717 Blausee-Mitholz",
        "3718 Kandersteg",
        "3722 Scharnachtal",
        "3723 Kiental",
        "3724 Ried (Frutigen)",
        "3725 Achseten",
        "3752 Wimmis",
        "3753 Oey",
        "3754 Diemtigen",
        "3755 Horboden",
        "3756 Zwischenflüh",
        "3757 Schwenden im Diemtigtal",
        "3758 Latterbach",
        "3762 Erlenbach im Simmental",
        "3763 Därstetten",
        "3764 Weissenburg",
        "3765 Oberwil im Simmental",
        "3706 Leissigen",
        "3707 Därligen",
        "3800 Sundlauenen",
        "3800 Matten b. Interlaken",
        "3800 Unterseen",
        "3800 Interlaken",
        "3800 Interlaken",
        "3803 Beatenberg",
        "3804 Habkern",
        "3805 Goldswil b. Interlaken",
        "3806 Bönigen b. Interlaken",
        "3807 Iseltwald",
        "3812 Wilderswil",
        "3813 Saxeten",
        "3814 Gsteigwiler",
        "3815 Zweilütschinen",
        "3815 Gündlischwand",
        "3816 Lütschental",
        "3816 Burglauenen",
        "3818 Grindelwald",
        "3822 Lauterbrunnen",
        "3822 Isenfluh",
        "3823 Eigergletscher",
        "3823 Wengen",
        "3823 Kleine Scheidegg",
        "3824 Stechelberg",
        "3825 Mürren",
        "3826 Gimmelwald",
        "3852 Ringgenberg BE",
        "3853 Niederried b. Interlaken",
        "3854 Oberried am Brienzersee",
        "3855 Schwanden b. Brienz",
        "3855 Brienz BE",
        "3855 Axalp",
        "3856 Brienzwiler",
        "3857 Unterbach BE",
        "3858 Hofstetten b. Brienz",
        "3860 Meiringen",
        "3860 Rosenlaui",
        "3860 Schattenhalb",
        "3860 Brünig",
        "3862 Innertkirchen",
        "3863 Gadmen",
        "3864 Guttannen",
        "6083 Hasliberg Hohfluh",
        "6084 Hasliberg Wasserwendi",
        "6085 Hasliberg Goldern",
        "6086 Hasliberg Reuti",
        "4101 Bruderholz",
        "4102 Binningen",
        "4103 Bottmingen",
        "4104 Oberwil BL",
        "4105 Biel-Benken BL",
        "4106 Therwil",
        "4107 Ettingen",
        "4123 Allschwil",
        "4124 Schönenbuch",
        "4127 Birsfelden",
        "4132 Muttenz",
        "4142 Münchenstein",
        "4144 Arlesheim",
        "4147 Aesch BL",
        "4148 Pfeffingen",
        "4153 Reinach BL",
        "2814 Roggenburg",
        "4117 Burg im Leimental",
        "4202 Duggingen",
        "4203 Grellingen",
        "4222 Zwingen",
        "4223 Blauen",
        "4224 Nenzlingen",
        "4225 Brislach",
        "4242 Laufen",
        "4243 Dittingen",
        "4244 Röschenz",
        "4246 Wahlen b. Laufen",
        "4253 Liesberg",
        "4254 Liesberg Dorf",
        "4133 Pratteln",
        "4302 Augst BL",
        "4304 Giebenach",
        "4402 Frenkendorf",
        "4410 Liestal",
        "4411 Seltisberg",
        "4414 Füllinsdorf",
        "4415 Lausen",
        "4416 Bubendorf",
        "4417 Ziefen",
        "4419 Lupsingen",
        "4422 Arisdorf",
        "4423 Hersberg",
        "4433 Ramlinsburg",
        "4441 Thürnen",
        "4442 Diepflingen",
        "4443 Wittinsburg",
        "4444 Rümlingen",
        "4445 Häfelfingen",
        "4446 Buckten",
        "4447 Känerkinden",
        "4448 Läufelfingen",
        "4450 Sissach",
        "4451 Wintersingen",
        "4452 Itingen",
        "4453 Nusshof",
        "4455 Zunzgen",
        "4456 Tenniken",
        "4460 Gelterkinden",
        "4461 Böckten",
        "4462 Rickenbach BL",
        "4463 Buus",
        "4464 Maisprach",
        "4465 Hemmiken",
        "4466 Ormalingen",
        "4467 Rothenfluh",
        "4469 Anwil",
        "4492 Tecknau",
        "4493 Wenslingen",
        "4494 Oltingen",
        "4495 Zeglingen",
        "4496 Kilchberg BL",
        "4497 Rünenberg",
        "4207 Bretzwil",
        "4418 Reigoldswil",
        "4424 Arboldswil",
        "4425 Titterten",
        "4426 Lauwil",
        "4431 Bennwil",
        "4432 Lampenberg",
        "4434 Hölstein",
        "4435 Niederdorf",
        "4436 Liedertswil",
        "4436 Oberdorf BL",
        "4437 Waldenburg",
        "4438 Langenbruck",
        "4457 Diegten",
        "4458 Eptingen",
        "4001 Basel",
        "4051 Basel",
        "4052 Basel",
        "4052 Basel",
        "4053 Basel",
        "4054 Basel",
        "4055 Basel",
        "4056 Basel",
        "4057 Basel",
        "4058 Basel",
        "4059 Basel",
        "4125 Riehen",
        "4126 Bettingen",
        "1410 Prévondavaux",
        "1468 Cheyres",
        "1470 Lully FR",
        "1470 Bollion",
        "1470 Estavayer-le-Lac",
        "1470 Seiry",
        "1473 Font",
        "1473 Châtillon FR",
        "1474 Châbles FR",
        "1475 Autavaux",
        "1475 Montbrelloz",
        "1475 Forel FR",
        "1482 Cugy FR",
        "1483 Vesin",
        "1483 Montet (Broye)",
        "1483 Frasses",
        "1484 Granges-de-Vesin",
        "1484 Aumont",
        "1485 Nuvilly",
        "1486 Vuissens",
        "1489 Murist",
        "1527 Villeneuve FR",
        "1528 Praratoud",
        "1528 Surpierre",
        "1529 Cheiry",
        "1532 Fétigny",
        "1533 Ménières",
        "1534 Chapelle (Broye)",
        "1541 Bussy FR",
        "1541 Sévaz",
        "1541 Morens FR",
        "1542 Rueyres-les-Prés",
        "1544 Gletterens",
        "1563 Dompierre FR",
        "1564 Domdidier",
        "1565 Vallon",
        "1566 Les Friques",
        "1566 St-Aubin FR",
        "1567 Delley",
        "1568 Portalban",
        "1773 Chandon",
        "1773 Léchelles",
        "1773 Russy",
        "1774 Montagny-les-Monts",
        "1774 Cousset",
        "1774 Cousset",
        "1775 Grandsivaz",
        "1775 Mannens",
        "1776 Montagny-la-Ville",
        "1553 Châtonnaye",
        "1608 Chapelle (Glâne)",
        "1670 Ursy",
        "1670 Esmonts",
        "1670 Bionnens",
        "1673 Ecublens FR",
        "1673 Gillarens",
        "1673 Auboranges",
        "1673 Promasens",
        "1673 Rue",
        "1674 Morlens",
        "1674 Montet (Glâne)",
        "1674 Vuarmarens",
        "1675 Blessens",
        "1675 Mossel",
        "1675 Vauderens",
        "1676 Chavannes-les-Forts",
        "1677 Prez-vers-Siviriez",
        "1678 Siviriez",
        "1679 Villaraboud",
        "1680 Berlens",
        "1680 Romont FR",
        "1681 Hennens",
        "1681 Billens",
        "1684 Mézières FR",
        "1685 Villariaz",
        "1686 Grangettes-près-Romont",
        "1686 La Neirigue",
        "1687 Estévenens",
        "1687 Vuisternens-devant-Romont",
        "1687 La Magne",
        "1688 Sommentier",
        "1688 Lieffrens",
        "1689 Le Châtelard-près-Romont",
        "1690 Villaz-St-Pierre",
        "1690 Lussy FR",
        "1691 Villarimboud",
        "1692 Massonnens",
        "1694 Chavannes-sous-Orsonnens",
        "1694 Orsonnens",
        "1694 Villarsiviriaux",
        "1694 Villargiroud",
        "1697 Les Ecasseys",
        "1697 La Joux FR",
        "1748 Torny-le-Grand",
        "1749 Middes",
        "1625 Maules",
        "1625 Sâles (Gruyère)",
        "1626 Treyfayes",
        "1626 Rueyres-Treyfayes",
        "1626 Romanens",
        "1627 Vaulruz",
        "1628 Vuadens",
        "1630 Bulle",
        "1632 Riaz",
        "1633 Marsens",
        "1633 Vuippens",
        "1634 La Roche FR",
        "1635 La Tour-de-Trême",
        "1636 Broc",
        "1637 Charmey (Gruyère)",
        "1638 Morlon",
        "1642 Sorens",
        "1643 Gumefens",
        "1644 Avry-devant-Pont",
        "1645 Le Bry",
        "1646 Echarlens",
        "1647 Corbières",
        "1648 Hauteville",
        "1649 Pont-la-Ville",
        "1651 Villarvolard",
        "1652 Botterens",
        "1652 Villarbeney",
        "1653 Crésuz",
        "1653 Châtel-sur-Montsalvens",
        "1654 Cerniat FR",
        "1656 Im Fang",
        "1656 Jaun",
        "1661 Le Pâquier-Montbarry",
        "1663 Moléson-sur-Gruyères",
        "1663 Gruyères",
        "1663 Epagny",
        "1663 Pringy",
        "1665 Estavannens",
        "1666 Grandvillard",
        "1666 Villars-sous-Mont",
        "1667 Enney",
        "1669 Montbovon",
        "1669 Les Sciernes-d'Albeuve",
        "1669 Albeuve",
        "1669 Neirivue",
        "1669 Lessoc",
        "1695 Estavayer-le-Gibloux",
        "1695 Villarsel-le-Gibloux",
        "1695 Rueyres-St-Laurent",
        "1695 Villarlod",
        "1696 Vuisternens-en-Ogoz",
        "1700 Fribourg",
        "1720 Chésopelloz",
        "1720 Corminboeuf",
        "1722 Bourguillon",
        "1723 Pierrafortscha",
        "1723 Villarsel-sur-Marly",
        "1723 Marly",
        "1724 Oberried FR",
        "1724 Zénauva",
        "1724 Senèdes",
        "1724 Le Mouret",
        "1724 Bonnefontaine",
        "1724 Essert FR",
        "1724 Ferpicloz",
        "1724 Montévraz",
        "1725 Posieux",
        "1726 Farvagny",
        "1726 Grenilles",
        "1726 Posat",
        "1726 Farvagny-le-Petit",
        "1727 Corpataux",
        "1727 Magnedens",
        "1728 Rossens FR",
        "1730 Ecuvillens",
        "1731 Ependes FR",
        "1732 Arconciel",
        "1733 Treyvaux",
        "1740 Neyruz FR",
        "1741 Cottens FR",
        "1742 Autigny",
        "1744 Chénens",
        "1745 Lentigny",
        "1746 Prez-vers-Noréaz",
        "1747 Corserey",
        "1752 Villars-sur-Glâne",
        "1753 Matran",
        "1754 Avry-sur-Matran",
        "1754 Corjolens",
        "1754 Rosé",
        "1756 Onnens FR",
        "1756 Lovens",
        "1757 Noréaz",
        "1762 Givisiez",
        "1763 Granges-Paccot",
        "1772 Ponthaux",
        "1772 Nierlet-les-Bois",
        "1772 Grolley",
        "1782 La Corbaz",
        "1782 Lossy",
        "1782 Belfaux",
        "1782 Formangueires",
        "1782 Autafond",
        "1782 Cormagens",
        "1583 Villarepos",
        "1721 Misery",
        "1721 Courtion",
        "1721 Cournillens",
        "1721 Cormérod",
        "1783 Pensier",
        "1783 Barberêche",
        "1784 Courtepin",
        "1784 Wallenried",
        "1785 Cressier FR",
        "1786 Sugiez",
        "1787 Môtier (Vully)",
        "1787 Mur (Vully) FR",
        "1788 Praz (Vully)",
        "1789 Lugnorre",
        "1791 Courtaman",
        "1792 Guschelmuth",
        "1792 Cordast",
        "1793 Jeuss",
        "1794 Salvenach",
        "1795 Courlevon",
        "1796 Courgevaux",
        "3206 Wallenbuch",
        "3210 Kerzers",
        "3212 Gurmels",
        "3212 Kleingurmels",
        "3213 Liebistorf",
        "3213 Kleinbösingen",
        "3214 Ulmiz",
        "3215 Lurtigen",
        "3215 Büchslen",
        "3215 Gempenach",
        "3216 Ried b. Kerzers",
        "3216 Agriswil",
        "3280 Murten",
        "3280 Greng",
        "3280 Meyriez",
        "3284 Fräschels",
        "3285 Galmiz",
        "3286 Muntelier",
        "1712 Tafers",
        "1713 St. Antoni",
        "1714 Heitenried",
        "1715 Alterswil FR",
        "1716 Schwarzsee",
        "1716 Plaffeien",
        "1716 Oberschrot",
        "1717 St. Ursen",
        "1718 Rechthalten",
        "1719 Brünisried",
        "1719 Zumholz",
        "1734 Tentlingen",
        "1735 Giffers",
        "1736 St. Silvester",
        "1737 Plasselb",
        "3175 Flamatt",
        "3178 Bösingen",
        "3182 Ueberstorf",
        "3184 Wünnewil",
        "3185 Schmitten FR",
        "3186 Düdingen",
        "1609 Fiaugères",
        "1609 St-Martin FR",
        "1609 Besencens",
        "1609 Fiaugères",
        "1611 Le Crêt-près-Semsales",
        "1614 Granges (Veveyse)",
        "1615 Bossonnens",
        "1616 Attalens",
        "1617 Remaufens",
        "1617 Tatroz",
        "1618 Châtel-St-Denis",
        "1619 Les Paccots",
        "1623 Semsales",
        "1624 La Verrerie",
        "1624 Progens",
        "1624 Grattavache",
        "1624 Progens",
        "1699 Pont (Veveyse)",
        "1699 Bouloz",
        "1699 Porsel",
        "1699 Porsel",
        "1201 Genève",
        "1202 Genève",
        "1203 Genève",
        "1204 Genève",
        "1205 Genève",
        "1206 Genève",
        "1207 Genève",
        "1208 Genève",
        "1209 Genève",
        "1212 Grand-Lancy",
        "1213 Petit-Lancy",
        "1213 Onex",
        "1214 Vernier",
        "1215 Genève",
        "1216 Cointrin",
        "1217 Meyrin",
        "1218 Le Grand-Saconnex",
        "1219 Le Lignon",
        "1219 Aïre",
        "1219 Châtelaine",
        "1220 Les Avanchets",
        "1222 Vésenaz",
        "1223 Cologny",
        "1224 Chêne-Bougeries",
        "1225 Chêne-Bourg",
        "1226 Thônex",
        "1227 Les Acacias",
        "1227 Carouge GE",
        "1228 Plan-les-Ouates",
        "1231 Conches",
        "1232 Confignon",
        "1233 Bernex",
        "1234 Vessy",
        "1236 Cartigny",
        "1237 Avully",
        "1239 Collex",
        "1241 Puplinge",
        "1242 Satigny",
        "1243 Presinge",
        "1244 Choulex",
        "1245 Collonge-Bellerive",
        "1246 Corsier GE",
        "1247 Anières",
        "1248 Hermance",
        "1251 Gy",
        "1252 Meinier",
        "1253 Vandoeuvres",
        "1254 Jussy",
        "1255 Veyrier",
        "1256 Troinex",
        "1257 La Croix-de-Rozon",
        "1258 Perly",
        "1281 Russin",
        "1283 Dardagny",
        "1283 La Plaine",
        "1284 Chancy",
        "1285 Athenaz (Avusy)",
        "1286 Soral",
        "1287 Laconnex",
        "1288 Aire-la-Ville",
        "1290 Versoix",
        "1292 Chambésy",
        "1293 Bellevue",
        "1294 Genthod",
        "1298 Céligny",
        "1298 Céligny",
        "8750 Glarus",
        "8750 Riedern",
        "8750 Klöntal",
        "8752 Näfels",
        "8753 Mollis",
        "8754 Netstal",
        "8755 Ennenda",
        "8756 Mitlödi",
        "8757 Filzbach",
        "8758 Obstalden",
        "8762 Schwändi b. Schwanden",
        "8762 Sool",
        "8762 Schwanden GL",
        "8765 Engi",
        "8766 Matt",
        "8767 Elm",
        "8772 Nidfurn",
        "8773 Haslen GL",
        "8774 Leuggelbach",
        "8775 Luchsingen",
        "8775 Hätzingen",
        "8777 Diesbach GL",
        "8777 Betschwanden",
        "8782 Rüti GL",
        "8783 Linthal",
        "8784 Braunwald",
        "8865 Bilten",
        "8867 Niederurnen",
        "8868 Oberurnen",
        "8874 Mühlehorn",
        "7077 Valbella",
        "7078 Lenzerheide/Lai",
        "7082 Vaz/Obervaz",
        "7083 Lantsch/Lenz",
        "7084 Brienz/Brinzauls GR",
        "7450 Tiefencastel",
        "7450 Tiefencastel",
        "7451 Alvaschein",
        "7452 Cunter",
        "7453 Tinizong",
        "7454 Rona",
        "7455 Mulegns",
        "7456 Marmorera",
        "7456 Sur",
        "7457 Bivio",
        "7458 Mon",
        "7459 Stierva",
        "7460 Savognin",
        "7462 Salouf",
        "7463 Riom",
        "7463 Riom",
        "7464 Parsonz",
        "7472 Surava",
        "7473 Alvaneu Bad",
        "7477 Filisur",
        "7482 Preda",
        "7482 Stugl/Stuls",
        "7482 Bergün/Bravuogn",
        "7484 Latsch",
        "7492 Alvaneu Dorf",
        "7493 Schmitten (Albula)",
        "7710 Ospizio Bernina",
        "7710 Alp Grüm",
        "7741 S. Carlo (Poschiavo)",
        "7742 La Rösa",
        "7742 Poschiavo",
        "7742 Sfazù",
        "7743 Brusio",
        "7743 Miralago",
        "7744 Campocologno",
        "7745 Li Curt",
        "7746 Le Prese",
        "7747 Viano",
        "7748 Campascio",
        "7527 Brail",
        "7530 Zernez",
        "7532 Tschierv",
        "7533 Fuldera",
        "7534 Lü",
        "7535 Valchava",
        "7536 Sta. Maria Val Müstair",
        "7537 Müstair",
        "7542 Susch",
        "7543 Lavin",
        "7545 Guarda",
        "7546 Ardez",
        "7550 Scuol",
        "7551 Ftan",
        "7552 Vulpera",
        "7553 Tarasp",
        "7554 Crusch",
        "7554 Sent",
        "7556 Ramosch",
        "7556 Ramosch",
        "7557 Vnà",
        "7558 Strada",
        "7559 Tschlin",
        "7560 Martina",
        "7562 Samnaun-Compatsch",
        "7563 Samnaun Dorf",
        "7012 Felsberg",
        "7013 Domat/Ems",
        "7014 Trin",
        "7015 Tamins",
        "7016 Trin Mulin",
        "7017 Flims Dorf",
        "7018 Flims Waldhaus",
        "7019 Fidaz",
        "7402 Bonaduz",
        "7403 Rhäzüns",
        "7202 Says",
        "7203 Trimmis",
        "7204 Untervaz",
        "7205 Zizers",
        "7206 Igis",
        "7208 Malans GR",
        "7302 Landquart",
        "7303 Mastrils",
        "7304 Maienfeld",
        "7306 Fläsch",
        "7307 Jenins",
        "7500 St. Moritz",
        "7502 Bever",
        "7502 Bever",
        "7503 Samedan",
        "7504 Pontresina",
        "7505 Celerina/Schlarigna",
        "7512 Champfèr",
        "7513 Silvaplana-Surlej",
        "7513 Silvaplana",
        "7514 Sils/Segl Maria",
        "7514 Fex",
        "7515 Sils/Segl Baselgia",
        "7516 Maloja",
        "7517 Plaun da Lej",
        "7522 La Punt-Chamues-ch",
        "7523 Madulain",
        "7523 Madulain",
        "7524 Zuoz",
        "7524 Zuoz",
        "7525 S-chanf",
        "7526 Cinuos-chel",
        "7602 Casaccia",
        "7603 Vicosoprano",
        "7604 Borgonovo",
        "7605 Stampa",
        "7606 Promontogno",
        "7606 Bondo",
        "7608 Castasegna",
        "7610 Soglio",
        "6534 S. Vittore",
        "6534 S. Vittore",
        "6535 Roveredo GR",
        "6537 Grono",
        "6538 Verdabbio",
        "6538 Verdabbio",
        "6540 Castaneda",
        "6541 Sta. Maria in Calanca",
        "6542 Buseno",
        "6543 Arvigo",
        "6544 Braggio",
        "6545 Selma",
        "6546 Cauco",
        "6547 Augio",
        "6548 Rossa",
        "6549 Laura",
        "6556 Leggia",
        "6557 Cama",
        "6558 Lostallo",
        "6562 Soazza",
        "6563 Mesocco",
        "6565 S. Bernardino",
        "7000 Chur",
        "7023 Haldenstein",
        "7026 Maladers",
        "7027 Castiel",
        "7027 Calfreisen",
        "7027 Lüen",
        "7028 St. Peter",
        "7028 Pagig",
        "7029 Peist",
        "7050 Arosa",
        "7056 Molinis",
        "7057 Langwies",
        "7058 Litzirüti",
        "7062 Passugg",
        "7063 Praden",
        "7064 Tschiertschen",
        "7074 Malix",
        "7075 Churwalden",
        "7076 Parpan",
        "7212 Seewis-Pardisla",
        "7212 Seewis Dorf",
        "7212 Seewis-Schmitten",
        "7213 Valzeina",
        "7214 Grüsch",
        "7215 Fanas",
        "7220 Schiers",
        "7220 Schiers",
        "7222 Lunden",
        "7223 Buchen im Prättigau",
        "7224 Putz",
        "7226 Fajauna",
        "7226 Stels",
        "7226 Stels",
        "7228 Schuders",
        "7228 Pusserein",
        "7231 Pragg-Jenaz",
        "7232 Furna",
        "7233 Jenaz",
        "7235 Fideris",
        "7240 Küblis",
        "7241 Conters im Prättigau",
        "7242 Luzein",
        "7243 Pany",
        "7244 Gadenstätt",
        "7245 Ascharina",
        "7246 St. Antönien",
        "7247 Saas im Prättigau",
        "7249 Serneus",
        "7250 Klosters",
        "7252 Klosters Dorf",
        "7260 Davos Dorf",
        "7265 Davos Wolfgang",
        "7270 Davos Platz",
        "7272 Davos Clavadel",
        "7276 Davos Frauenkirch",
        "7277 Davos Glaris",
        "7278 Davos Monstein",
        "7494 Davos Wiesen",
        "7031 Laax GR",
        "7032 Laax GR 2",
        "7104 Arezen",
        "7104 Versam",
        "7104 Versam",
        "7106 Tenna",
        "7107 Safien Platz",
        "7109 Thalkirch",
        "7110 Peiden",
        "7111 Pitasch",
        "7112 Duvin",
        "7113 Camuns",
        "7114 Uors (Lumnezia)",
        "7115 Surcasti",
        "7116 St. Martin (Lugnez)",
        "7116 Tersnaus",
        "7122 Carrera",
        "7122 Valendas",
        "7126 Castrisch",
        "7127 Sevgein",
        "7128 Riein",
        "7130 Ilanz",
        "7130 Schnaus",
        "7130 Schnaus",
        "7132 Vals",
        "7134 Obersaxen",
        "7137 Flond",
        "7138 Surcuolm",
        "7141 Luven",
        "7142 Cumbel",
        "7143 Morissen",
        "7144 Vella",
        "7145 Degen",
        "7146 Vattiz",
        "7147 Vignogn",
        "7148 Lumbrein",
        "7149 Vrin",
        "7151 Schluein",
        "7152 Sagogn",
        "7153 Falera",
        "7154 Ruschein",
        "7155 Ladir",
        "7155 Ladir",
        "7156 Pigniu",
        "7156 Rueun",
        "7157 Siat",
        "7158 Waltensburg/Vuorz",
        "7159 Andiast",
        "7162 Tavanasa",
        "7163 Danis",
        "7164 Dardin",
        "7165 Breil/Brigels",
        "7166 Trun",
        "7167 Zignau",
        "7168 Schlans",
        "7172 Rabius",
        "7173 Surrein",
        "7174 S. Benedetg",
        "7175 Sumvitg",
        "7176 Cumpadials",
        "7180 Disentis/Mustér",
        "7182 Cavardiras",
        "7183 Mumpé Medel",
        "7184 Curaglia",
        "7185 Platta",
        "7186 Segnas",
        "7187 Camischolas",
        "7188 Sedrun",
        "7189 Rueras",
        "7404 Feldis/Veulden",
        "7405 Rothenbrunnen",
        "7407 Trans",
        "7408 Realta",
        "7408 Cazis",
        "7411 Sils im Domleschg",
        "7412 Scharans",
        "7413 Fürstenaubruck",
        "7414 Fürstenau",
        "7415 Pratval",
        "7415 Rodels",
        "7416 Almens",
        "7417 Paspels",
        "7418 Tumegl/Tomils",
        "7419 Scheid",
        "7421 Summaprada",
        "7422 Tartar",
        "7423 Sarn",
        "7423 Portein",
        "7424 Dalin",
        "7424 Präz",
        "7425 Masein",
        "7426 Flerden",
        "7426 Flerden",
        "7427 Urmein",
        "7428 Glaspass",
        "7428 Tschappina",
        "7430 Rongellen",
        "7430 Thusis",
        "7431 Mutten",
        "7431 Obermutten",
        "7432 Zillis",
        "7433 Farden",
        "7433 Mathon",
        "7433 Lohn GR",
        "7433 Donat",
        "7433 Wergenstein",
        "7434 Sufers",
        "7435 Splügen",
        "7436 Medels im Rheinwald",
        "7437 Nufenen",
        "7438 Hinterrhein",
        "7440 Andeer",
        "7442 Clugin",
        "7443 Pignia",
        "7444 Ausserferrera",
        "7445 Innerferrera",
        "7445 Innerferrera",
        "7446 Campsut-Cröt",
        "7447 Cresta (Avers)",
        "7447 Am Bach (Avers)",
        "7448 Juf",
        "2800 Delémont",
        "2802 Develier",
        "2803 Bourrignon",
        "2805 Soyhières",
        "2806 Mettembert",
        "2807 Pleigne",
        "2807 Lucelle",
        "2812 Movelier",
        "2813 Ederswiler",
        "2822 Courroux",
        "2823 Courcelon",
        "2824 Vicques",
        "2825 Courchapoix",
        "2826 Corban",
        "2827 Mervelier",
        "2828 Montsevelier",
        "2829 Vermes",
        "2830 Courrendlin",
        "2830 Vellerat",
        "2832 Rebeuvelier",
        "2842 Rossemaison",
        "2843 Châtillon JU",
        "2852 Courtételle",
        "2853 Courfaivre",
        "2854 Bassecourt",
        "2855 Glovelier",
        "2856 Boécourt",
        "2857 Montavon",
        "2863 Undervelier",
        "2864 Soulce",
        "2873 Saulcy",
        "2336 Les Bois",
        "2338 Muriaux",
        "2338 Les Emibois",
        "2340 Le Noirmont",
        "2345 La Chaux-des-Breuleux",
        "2345 Les Breuleux",
        "2350 Saignelégier",
        "2353 Les Pommerats",
        "2354 Goumois",
        "2360 Le Bémont JU",
        "2362 Montfaucon",
        "2362 Montfavergier",
        "2363 Les Enfers",
        "2364 St-Brais",
        "2714 Les Genevez JU",
        "2714 Le Prédame",
        "2718 Fornet-Dessus",
        "2718 Lajoux JU",
        "2882 St-Ursanne",
        "2887 Soubey",
        "2882 St-Ursanne",
        "2883 Montmelon",
        "2884 Montenol",
        "2885 Epauvillers",
        "2886 Epiquerez",
        "2888 Seleute",
        "2889 Ocourt",
        "2900 Porrentruy",
        "2902 Fontenais",
        "2903 Villars-sur-Fontenais",
        "2904 Bressaucourt",
        "2905 Courtedoux",
        "2906 Chevenez",
        "2907 Rocourt",
        "2908 Grandfontaine",
        "2912 Réclère",
        "2912 Roche-d'Or",
        "2914 Damvant",
        "2915 Bure",
        "2916 Fahy",
        "2922 Courchavon",
        "2923 Courtemaîche",
        "2924 Montignez",
        "2925 Buix",
        "2926 Boncourt",
        "2932 Coeuve",
        "2933 Damphreux",
        "2933 Lugnez",
        "2935 Beurnevésin",
        "2942 Alle",
        "2943 Vendlincourt",
        "2944 Bonfol",
        "2946 Miécourt",
        "2947 Charmoille",
        "2950 Courgenay",
        "2950 Courtemautruy",
        "2952 Cornol",
        "2953 Fregiécourt",
        "2953 Pleujouse",
        "2954 Asuel",
        "6003 Luzern",
        "6004 Luzern",
        "6005 Luzern",
        "6006 Luzern",
        "6014 Luzern",
        "6015 Luzern",
        "6005 St. Niklausen LU",
        "6010 Kriens",
        "6012 Obernau",
        "6013 Eigenthal",
        "6030 Ebikon",
        "6033 Buchrain",
        "6035 Perlen",
        "6036 Dierikon",
        "6037 Root",
        "6038 Gisikon",
        "6038 Honau",
        "6039 Root D4",
        "6043 Adligenswil",
        "6044 Udligenswil",
        "6045 Meggen",
        "6047 Kastanienbaum",
        "6048 Horw",
        "6102 Malters",
        "6103 Schwarzenberg LU",
        "6344 Meierskappel",
        "6353 Weggis",
        "6354 Vitznau",
        "6356 Rigi Kaltbad",
        "6404 Greppen",
        "6020 Emmenbrücke",
        "6023 Rothenburg",
        "6026 Rain",
        "6027 Römerswil LU",
        "6028 Herlisberg",
        "6032 Emmen",
        "6034 Inwil",
        "6274 Eschenbach LU",
        "6275 Ballwil",
        "6276 Hohenrain",
        "6277 Lieli LU",
        "6277 Kleinwangen",
        "6280 Urswil",
        "6280 Hochdorf",
        "6283 Baldegg",
        "6284 Gelfingen",
        "6284 Sulz LU",
        "6285 Retschwil",
        "6285 Hitzkirch",
        "6286 Altwis",
        "6287 Aesch LU",
        "6288 Schongau",
        "6289 Hämikon",
        "6289 Müswangen",
        "6289 Hämikon",
        "6294 Ermensee",
        "6295 Mosen",
        "5735 Pfeffikon LU",
        "6016 Hellbühl",
        "6017 Ruswil",
        "6018 Buttisholz",
        "6019 Sigigen",
        "6022 Grosswangen",
        "6024 Hildisrieden",
        "6025 Neudorf",
        "6203 Sempach Station",
        "6204 Sempach",
        "6205 Eich",
        "6206 Neuenkirch",
        "6207 Nottwil",
        "6208 Oberkirch LU",
        "6210 Sursee",
        "6212 St. Erhard",
        "6212 Kaltbach",
        "6213 Knutwil",
        "6214 Schenkon",
        "6215 Beromünster",
        "6215 Schwarzenbach LU",
        "6216 Mauensee",
        "6221 Rickenbach LU",
        "6222 Gunzwil",
        "6231 Schlierbach",
        "6232 Geuensee",
        "6233 Büron",
        "6234 Kulmerau",
        "6234 Triengen",
        "6235 Winikon",
        "6236 Wilihof",
        "4806 Wikon",
        "4915 St. Urban",
        "6122 Menznau",
        "6123 Geiss",
        "6125 Menzberg",
        "6126 Daiwil",
        "6130 Willisau",
        "6132 Rohrmatt",
        "6133 Hergiswil b. Willisau",
        "6142 Gettnau",
        "6143 Ohmstal",
        "6144 Zell LU",
        "6145 Fischbach LU",
        "6146 Grossdietwil",
        "6147 Altbüron",
        "6152 Hüswil",
        "6153 Ufhusen",
        "6154 Hofstatt",
        "6156 Luthern",
        "6156 Luthern Bad",
        "6211 Buchs LU",
        "6217 Kottwil",
        "6218 Ettiswil",
        "6242 Wauwil",
        "6243 Egolzwil",
        "6244 Nebikon",
        "6245 Ebersecken",
        "6246 Altishofen",
        "6247 Schötz",
        "6248 Alberswil",
        "6252 Dagmersellen",
        "6253 Uffikon",
        "6260 Mehlsecken",
        "6260 Reiden",
        "6260 Reidermoos",
        "6260 Hintermoos",
        "6262 Langnau b. Reiden",
        "6263 Richenthal",
        "6264 Pfaffnau",
        "6265 Roggliswil",
        "6105 Schachen LU",
        "6106 Werthenstein",
        "6110 Wolhusen",
        "6110 Fontannen b. Wolhusen",
        "6112 Doppleschwand",
        "6113 Romoos",
        "6114 Steinhuserberg",
        "6162 Finsterwald b. Entlebuch",
        "6162 Entlebuch",
        "6162 Rengg",
        "6163 Ebnet",
        "6166 Hasle LU",
        "6167 Bramboden",
        "6170 Schüpfheim",
        "6173 Flühli LU",
        "6174 Sörenberg",
        "6182 Escholzmatt",
        "6192 Wiggen",
        "6196 Marbach LU",
        "2012 Auvernier",
        "2013 Colombier NE",
        "2014 Bôle",
        "2015 Areuse",
        "2016 Cortaillod",
        "2017 Boudry",
        "2019 Rochefort",
        "2019 Chambrelien",
        "2022 Bevaix",
        "2023 Gorgier",
        "2024 St-Aubin-Sauges",
        "2025 Chez-le-Bart",
        "2027 Fresens",
        "2027 Montalchez",
        "2028 Vaumarcus",
        "2034 Peseux",
        "2035 Corcelles NE",
        "2036 Cormondrèche",
        "2037 Montezillon",
        "2149 Fretereules",
        "2149 Champ-du-Moulin",
        "2149 Brot-Dessous",
        "2300 La Cibourg",
        "2300 La Chaux-de-Fonds",
        "2314 La Sagne NE",
        "2322 Le Crêt-du-Locle",
        "2325 Les Planchettes",
        "2333 La Cibourg",
        "2616 La Cibourg",
        "2316 Petit-Martel",
        "2316 Les Ponts-de-Martel",
        "2318 Brot-Plamboz",
        "2400 Le Locle",
        "2400 Le Prévoux",
        "2400 Le Locle",
        "2405 La Chaux-du-Milieu",
        "2406 La Châtagne",
        "2406 La Brévine",
        "2406 Le Brouillet",
        "2406 Les Taillères",
        "2414 Le Cerneux-Péquignot",
        "2416 Les Brenets",
        "2000 Neuchâtel",
        "2067 Chaumont",
        "2068 Hauterive NE",
        "2072 St-Blaise",
        "2073 Enges",
        "2074 Marin-Epagnier",
        "2075 Wavre",
        "2075 Thielle",
        "2087 Cornaux NE",
        "2088 Cressier NE",
        "2523 Lignières",
        "2525 Le Landeron",
        "2037 Montmollin",
        "2042 Valangin",
        "2043 Boudevilliers",
        "2046 Fontaines NE",
        "2052 Fontainemelon",
        "2052 La Vue-des-Alpes",
        "2053 Cernier",
        "2054 Les Vieux-Prés",
        "2054 Chézard-St-Martin",
        "2056 Dombresson",
        "2057 Villiers",
        "2058 Le Pâquier NE",
        "2063 Vilars NE",
        "2063 Fenin",
        "2063 Saules",
        "2063 Engollon",
        "2065 Savagnier",
        "2206 Les Geneveys-sur-Coffrane",
        "2207 Coffrane",
        "2208 Les Hauts-Geneveys",
        "2103 Noiraigue",
        "2105 Travers",
        "2108 Couvet",
        "2112 Môtiers NE",
        "2113 Boveresse",
        "2114 Fleurier",
        "2115 Buttes",
        "2116 Mont-de-Buttes",
        "2117 La Côte-aux-Fées",
        "2123 St-Sulpice NE",
        "2124 Les Sagnettes",
        "2126 Les Verrières",
        "2127 Les Bayards",
        "6052 Hergiswil NW",
        "6362 Stansstad",
        "6363 Fürigen",
        "6363 Obbürgen",
        "6363 Bürgenstock",
        "6365 Kehrsiten",
        "6370 Stans",
        "6370 Oberdorf NW",
        "6372 Ennetmoos",
        "6373 Ennetbürgen",
        "6374 Buochs",
        "6375 Beckenried",
        "6376 Emmetten",
        "6382 Büren NW",
        "6383 Niederrickenbach",
        "6383 Wirzweli",
        "6383 Dallenwil",
        "6383 Wiesenberg",
        "6386 Wolfenschiessen",
        "6387 Oberrickenbach",
        "6010 Kriens",
        "6053 Alpnachstad",
        "6055 Alpnach Dorf",
        "6056 Kägiswil",
        "6060 Sarnen",
        "6060 Ramersberg",
        "6062 Wilen (Sarnen)",
        "6063 Stalden (Sarnen)",
        "6064 Kerns",
        "6066 St. Niklausen OW",
        "6067 Melchtal",
        "6068 Melchsee-Frutt",
        "6072 Sachseln",
        "6073 Flüeli-Ranft",
        "6074 Giswil",
        "6078 Bürglen OW",
        "6078 Lungern",
        "6388 Grafenort",
        "6390 Engelberg",
        "9000 St. Gallen",
        "9008 St. Gallen",
        "9010 St. Gallen",
        "9011 St. Gallen",
        "9012 St. Gallen",
        "9014 St. Gallen",
        "9015 St. Gallen",
        "9016 St. Gallen",
        "9030 Abtwil SG",
        "9030 St. Josefen",
        "9032 Engelburg",
        "9034 Eggersriet",
        "9036 Grub SG",
        "9200 Gossau SG",
        "9204 Andwil SG",
        "9205 Waldkirch",
        "9212 Arnegg",
        "9300 Wittenbach",
        "9304 Bernhardzell",
        "9308 Lömmenschwil",
        "9312 Häggenschwil",
        "9313 Muolen",
        "9033 Untereggen",
        "9305 Berg SG",
        "9323 Steinach",
        "9327 Tübach",
        "9400 Rorschach",
        "9402 Mörschwil",
        "9403 Goldach",
        "9404 Rorschacherberg",
        "9422 Staad SG",
        "9423 Altenrhein",
        "9425 Thal",
        "9411 Reute AR",
        "9424 Rheineck",
        "9430 St. Margrethen SG",
        "9434 Au SG",
        "9435 Heerbrugg",
        "9436 Balgach",
        "9437 Marbach SG",
        "9442 Berneck",
        "9443 Widnau",
        "9444 Diepoldsau",
        "9445 Rebstein",
        "9450 Altstätten SG",
        "9450 Lüchingen",
        "9451 Kriessern",
        "9452 Hinterforst",
        "9453 Eichberg",
        "9462 Montlingen",
        "9462 Montlingen",
        "9463 Oberriet SG",
        "9464 Rüthi (Rheintal)",
        "9464 Lienz",
        "9465 Salez",
        "9466 Sennwald",
        "9467 Frümsen",
        "9468 Sax",
        "9469 Haag (Rheintal)",
        "9470 Werdenberg",
        "9470 Buchs SG",
        "9472 Grabserberg",
        "9472 Grabs",
        "9473 Gams",
        "9475 Sevelen",
        "9476 Fontnas",
        "9476 Weite",
        "9477 Trübbach",
        "9478 Azmoos",
        "9479 Malans SG",
        "9479 Oberschan",
        "9479 Gretschins",
        "7310 Bad Ragaz",
        "7310 Bad Ragaz",
        "7312 Pfäfers",
        "7313 St. Margrethenberg",
        "7314 Vadura",
        "7315 Vättis",
        "7317 Valens",
        "7317 Vasön",
        "7320 Sargans",
        "7323 Wangs",
        "7324 Vilters",
        "7325 Schwendi im Weisstannental",
        "7326 Weisstannen",
        "8877 Murg",
        "8878 Quinten",
        "8880 Walenstadt",
        "8881 Walenstadtberg",
        "8881 Tscherlach",
        "8882 Unterterzen",
        "8883 Quarten",
        "8884 Oberterzen",
        "8885 Mols",
        "8886 Mädris-Vermol",
        "8887 Mels",
        "8888 Heiligkreuz (Mels)",
        "8889 Plons",
        "8890 Flums",
        "8892 Berschis",
        "8893 Flums Hochwiese",
        "8894 Flumserberg Saxli",
        "8895 Flumserberg Portels",
        "8896 Flumserberg Bergheim",
        "8897 Flumserberg Tannenheim",
        "8898 Flumserberg Tannenbodenalp",
        "8638 Goldingen",
        "8640 Rapperswil SG",
        "8645 Jona",
        "8646 Wagen",
        "8715 Bollingen",
        "8716 Schmerikon",
        "8717 Benken SG",
        "8718 Schänis",
        "8722 Kaltbrunn",
        "8723 Maseltrangen",
        "8723 Rufi",
        "8725 Ernetschwil",
        "8725 Gebertingen",
        "8726 Ricken SG",
        "8727 Walde SG",
        "8730 Uznach",
        "8732 Neuhaus SG",
        "8733 Eschenbach SG",
        "8734 Ermenswil",
        "8735 Rüeterswil",
        "8735 St. Gallenkappel",
        "8737 Gommiswald",
        "8738 Uetliburg SG",
        "8739 Rieden SG",
        "8866 Ziegelbrücke",
        "8872 Weesen",
        "8873 Amden",
        "9114 Hoffeld",
        "9115 Dicken",
        "9122 Ebersol",
        "9122 Mogelsberg",
        "9123 Nassen",
        "9125 Brunnadern",
        "9126 Necker",
        "9127 St. Peterzell",
        "9500 Wil SG",
        "9533 Kirchberg SG",
        "9533 Dietschwil",
        "9534 Gähwil",
        "9601 Lütisburg Station",
        "9602 Müselbach",
        "9602 Bazenheid",
        "9604 Lütisburg",
        "9604 Unterrindal",
        "9606 Bütschwil",
        "9607 Mosnang",
        "9608 Ganterschwil",
        "9612 Dreien",
        "9613 Mühlrüti",
        "9614 Libingen",
        "9615 Dietfurt",
        "9620 Lichtensteig",
        "9621 Oberhelfenschwil",
        "9622 Krinau",
        "9630 Wattwil",
        "9631 Ulisbach",
        "9633 Hemberg",
        "9633 Bächli (Hemberg)",
        "9642 Ebnat-Kappel",
        "9643 Krummenau",
        "9650 Nesslau",
        "9651 Ennetbühl",
        "9652 Neu St. Johann",
        "9655 Stein SG",
        "9656 Alt St. Johann",
        "9657 Unterwasser",
        "9658 Wildhaus",
        "9113 Degersheim",
        "9116 Wolfertswil",
        "9203 Niederwil SG",
        "9230 Flawil",
        "9231 Egg (Flawil)",
        "9240 Niederglatt SG",
        "9240 Uzwil",
        "9242 Oberuzwil",
        "9243 Jonschwil",
        "9244 Niederuzwil",
        "9245 Sonnental",
        "9245 Oberbüren",
        "9246 Niederbüren",
        "9247 Henau",
        "9248 Bichwil",
        "9249 Algetshausen",
        "9249 Niederstetten",
        "9249 Oberstetten",
        "9500 Wil SG",
        "9512 Rossrüti",
        "9523 Züberwangen",
        "9524 Zuzwil SG",
        "9525 Lenggenwil",
        "9526 Zuckenriet",
        "9527 Niederhelfenschwil",
        "9532 Rickenbach b. Wil",
        "9536 Schwarzenbach SG",
        "9552 Bronschhofen",
        "9604 Oberrindal",
        "8213 Neunkirch",
        "8214 Gächlingen",
        "8224 Löhningen",
        "8234 Stetten SH",
        "8235 Lohn SH",
        "8236 Opfertshofen SH",
        "8236 Büttenhardt",
        "8239 Dörflingen",
        "8240 Thayngen",
        "8241 Barzheim",
        "8242 Hofen SH",
        "8242 Bibern SH",
        "8243 Altdorf SH",
        "8200 Schaffhausen",
        "8203 Schaffhausen",
        "8207 Schaffhausen",
        "8208 Schaffhausen",
        "8212 Neuhausen am Rheinfall",
        "8222 Beringen",
        "8223 Guntmadingen",
        "8231 Hemmental",
        "8232 Merishausen",
        "8233 Bargen SH",
        "8454 Buchberg",
        "8455 Rüdlingen",
        "8225 Siblingen",
        "8226 Schleitheim",
        "8228 Beggingen",
        "8260 Stein am Rhein",
        "8261 Hemishofen",
        "8262 Ramsen",
        "8263 Buch SH",
        "8215 Hallau",
        "8216 Oberhallau",
        "8217 Wilchingen",
        "8218 Osterfingen",
        "8219 Trasadingen",
        "4622 Egerkingen",
        "4623 Neuendorf",
        "4624 Härkingen",
        "4625 Oberbuchsiten",
        "4626 Niederbuchsiten",
        "4628 Wolfwil",
        "4702 Oensingen",
        "4703 Kestenholz",
        "4710 Balsthal",
        "4712 Laupersdorf",
        "4713 Matzendorf",
        "4714 Aedermannsdorf",
        "4715 Herbetswil",
        "4716 Welschenrohr",
        "4716 Gänsbrunnen",
        "4717 Mümliswil",
        "4718 Holderbank SO",
        "4719 Ramiswil",
        "3253 Schnottwil",
        "3254 Balm b. Messen",
        "3254 Messen",
        "3307 Brunnenthal",
        "4571 Lüterkofen",
        "4571 Ichertswil",
        "4574 Nennigkofen",
        "4574 Lüsslingen",
        "4576 Tscheppach",
        "4577 Hessigkofen",
        "4578 Bibern SO",
        "4579 Gossliwil",
        "4581 Küttigkofen",
        "4582 Brügglen",
        "4583 Mühledorf SO",
        "4583 Aetigkofen",
        "4584 Lüterswil",
        "4584 Gächliwil",
        "4585 Biezwil",
        "4586 Kyburg-Buchegg",
        "4587 Aetingen",
        "4588 Oberramsern",
        "4588 Brittern",
        "4588 Unterramsern",
        "4108 Witterswil",
        "4112 Bättwil",
        "4112 Flüh",
        "4114 Hofstetten SO",
        "4115 Mariastein",
        "4116 Metzerlen",
        "4118 Rodersdorf",
        "4143 Dornach",
        "4145 Gempen",
        "4146 Hochwald",
        "4206 Seewen SO",
        "4412 Nuglar",
        "4413 Büren SO",
        "4421 St. Pantaleon",
        "4468 Kienberg",
        "4632 Trimbach",
        "4633 Hauenstein",
        "4634 Wisen SO",
        "4652 Winznau",
        "4653 Obergösgen",
        "4654 Lostorf",
        "4655 Rohr b. Olten",
        "4655 Stüsslingen",
        "5013 Niedergösgen",
        "5015 Erlinsbach SO",
        "4528 Zuchwil",
        "4542 Luterbach",
        "4543 Deitingen",
        "4552 Derendingen",
        "4553 Subingen",
        "4554 Etziken",
        "4554 Hüniken",
        "4556 Burgäschi",
        "4556 Bolken",
        "4556 Aeschi SO",
        "4556 Steinhof SO",
        "4557 Horriwil",
        "4558 Winistorf",
        "4558 Hersiwil",
        "4558 Heinrichswil",
        "4562 Biberist",
        "4563 Gerlafingen",
        "4564 Obergerlafingen",
        "4565 Recherswil",
        "4566 Kriegstetten",
        "4566 Halten",
        "4566 Oekingen",
        "4573 Lohn-Ammannsegg",
        "2540 Grenchen",
        "2544 Bettlach",
        "2545 Selzach",
        "4512 Bellach",
        "4513 Langendorf",
        "4514 Lommiswil",
        "4515 Oberdorf SO",
        "4515 Weissenstein b. Solothurn",
        "4522 Rüttenen",
        "4523 Niederwil SO",
        "4524 Balmberg",
        "4524 Günsberg",
        "4524 Oberbalmberg",
        "4525 Balm b. Günsberg",
        "4532 Feldbrunnen",
        "4533 Riedholz",
        "4534 Flumenthal",
        "4535 Hubersdorf",
        "4535 Kammersrohr",
        "4600 Olten",
        "4612 Wangen b. Olten",
        "4613 Rickenbach SO",
        "4614 Hägendorf",
        "4615 Allerheiligenberg",
        "4616 Kappel SO",
        "4617 Gunzgen",
        "4618 Boningen",
        "4629 Fulenbach",
        "4656 Starrkirch-Wil",
        "4657 Dulliken",
        "4658 Däniken SO",
        "5012 Schönenwerd",
        "5012 Eppenberg",
        "5012 Wöschnau",
        "5014 Gretzenbach",
        "5746 Walterswil SO",
        "4500 Solothurn",
        "4204 Himmelried",
        "4208 Nunningen",
        "4226 Breitenbach",
        "4227 Büsserach",
        "4228 Erschwil",
        "4229 Beinwil SO",
        "4232 Fehren",
        "4233 Meltingen",
        "4234 Zullwil",
        "4245 Kleinlützel",
        "4247 Grindel",
        "4252 Bärschwil",
        "8836 Bennau",
        "8840 Trachslau",
        "8840 Einsiedeln",
        "8841 Gross",
        "8844 Euthal",
        "8846 Willerzell",
        "8847 Egg SZ",
        "6410 Rigi Scheidegg",
        "6442 Gersau",
        "8640 Hurden",
        "8806 Bäch SZ",
        "8807 Freienbach",
        "8808 Pfäffikon SZ",
        "8832 Wollerau",
        "8832 Wilen b. Wollerau",
        "8832 Wollerau",
        "8834 Schindellegi",
        "8835 Feusisberg",
        "6402 Merlischachen",
        "6403 Küssnacht am Rigi",
        "6405 Immensee",
        "8852 Altendorf",
        "8853 Lachen SZ",
        "8854 Siebnen",
        "8854 Galgenen",
        "8855 Wangen SZ",
        "8856 Tuggen",
        "8857 Vorderthal",
        "8858 Innerthal",
        "8862 Schübelbach",
        "8863 Buttikon SZ",
        "8864 Reichenburg",
        "6410 Rigi Klösterli",
        "6410 Rigi Staffel",
        "6410 Goldau",
        "6410 Rigi Kulm",
        "6414 Oberarth",
        "6415 Arth",
        "6416 Steinerberg",
        "6417 Sattel",
        "6418 Rothenthurm",
        "6422 Steinen",
        "6423 Seewen SZ",
        "6424 Lauerz",
        "6430 Schwyz",
        "6432 Rickenbach b. Schwyz",
        "6433 Stoos SZ",
        "6434 Illgau",
        "6436 Bisisthal",
        "6436 Ried (Muotathal)",
        "6436 Muotathal",
        "6438 Ibach",
        "6440 Brunnen",
        "6443 Morschach",
        "6452 Riemenstalden",
        "8842 Unteriberg",
        "8843 Oberiberg",
        "8845 Studen SZ",
        "8849 Alpthal",
        "8580 Hagenwil b. Amriswil",
        "8580 Sommeri",
        "8580 Amriswil",
        "8580 Hefenhofen",
        "8580 Biessenhofen",
        "8581 Schocherswil",
        "8582 Dozwil",
        "8587 Oberaach",
        "8590 Romanshorn",
        "8592 Uttwil",
        "8593 Kesswil",
        "8599 Salmsach",
        "9306 Freidorf TG",
        "9314 Steinebrunn",
        "9315 Winden",
        "9315 Neukirch (Egnach)",
        "9320 Arbon",
        "9320 Stachen",
        "9320 Frasnacht",
        "9322 Egnach",
        "9325 Roggwil TG",
        "9326 Horn",
        "8252 Schlatt TG",
        "8253 Willisdorf",
        "8253 Diessenhofen",
        "8254 Basadingen",
        "8255 Schlattingen",
        "8259 Kaltenbach",
        "8259 Etzwilen",
        "8259 Rheinklingen",
        "8259 Wagenhausen",
        "8264 Eschenz",
        "8265 Mammern",
        "8266 Steckborn",
        "8267 Berlingen",
        "8500 Gerlikon",
        "8500 Frauenfeld",
        "8505 Pfyn",
        "8505 Dettighofen",
        "8506 Lanzenneunforn",
        "8507 Hörhausen",
        "8508 Homburg",
        "8512 Wetzikon TG",
        "8512 Thundorf",
        "8512 Lustdorf",
        "8524 Buch b. Frauenfeld",
        "8524 Uesslingen",
        "8525 Niederneunforn",
        "8525 Wilen b. Neunforn",
        "8526 Oberneunforn",
        "8532 Warth",
        "8532 Weiningen TG",
        "8535 Herdern",
        "8536 Hüttwilen",
        "8537 Uerschhausen",
        "8537 Nussbaumen TG",
        "8546 Kefikon TG",
        "8546 Islikon",
        "8547 Gachnang",
        "8552 Felben-Wellhausen",
        "8553 Hüttlingen",
        "8553 Harenwilen",
        "8553 Eschikofen",
        "8553 Mettendorf TG",
        "8555 Müllheim Dorf",
        "9507 Stettfurt",
        "9548 Matzingen",
        "8268 Mannenbach-Salenstein",
        "8268 Salenstein",
        "8269 Fruthwilen",
        "8272 Ermatingen",
        "8273 Triboltingen",
        "8274 Gottlieben",
        "8274 Tägerwilen",
        "8280 Kreuzlingen",
        "8558 Raperswilen",
        "8564 Hattenhausen",
        "8564 Hefenhausen",
        "8564 Engwilen",
        "8564 Lipperswil",
        "8564 Gunterswilen",
        "8564 Sonterswil",
        "8564 Wäldi",
        "8565 Hugelshofen",
        "8566 Ellighausen",
        "8566 Dotnacht",
        "8566 Lippoldswilen",
        "8566 Neuwilen",
        "8573 Alterswilen",
        "8573 Altishausen",
        "8573 Siegershausen",
        "8574 Lengwil",
        "8574 Dettighofen (Lengwil)",
        "8574 Oberhofen TG",
        "8574 Illighausen",
        "8574 Lengwil",
        "8585 Langrickenbach",
        "8585 Schönenbaumgarten",
        "8585 Zuben",
        "8585 Herrenhof",
        "8594 Güttingen",
        "8595 Altnau",
        "8596 Scherzingen",
        "8596 Münsterlingen",
        "8597 Landschlacht",
        "8598 Bottighofen",
        "8355 Aadorf",
        "8356 Ettenhausen TG",
        "8357 Guntershausen b. Aadorf",
        "8360 Eschlikon TG",
        "8360 Wallenwil",
        "8362 Balterswil",
        "8363 Bichelsee",
        "8370 Sirnach",
        "8371 Busswil TG",
        "8372 Wiezikon b. Sirnach",
        "8374 Dussnang",
        "8374 Oberwangen TG",
        "8376 Fischingen",
        "8376 Au TG",
        "8522 Aawangen",
        "8522 Häuslenen",
        "9502 Braunau",
        "9506 Lommis",
        "9508 Weingarten-Kalthäusern",
        "9532 Rickenbach b. Wil",
        "9535 Wilen b. Wil",
        "9542 Münchwilen TG",
        "9543 St. Margarethen TG",
        "9545 Wängi",
        "9546 Tuttwil",
        "9547 Wittenwil",
        "9553 Bettwiesen",
        "9554 Tägerschen",
        "9555 Tobel",
        "9573 Littenheid",
        "8514 Amlikon-Bissegg",
        "8554 Müllheim-Wigoltingen",
        "8554 Bonau",
        "8556 Illhart",
        "8556 Lamperswil TG",
        "8556 Engwang",
        "8556 Wigoltingen",
        "8560 Märstetten",
        "8561 Ottoberg",
        "8564 Wagerswil",
        "8570 Weinfelden",
        "8572 Guntershausen b. Berg",
        "8572 Graltshausen",
        "8572 Berg TG",
        "8572 Berg TG",
        "8572 Andhausen",
        "8575 Istighofen",
        "8575 Bürglen TG",
        "8576 Mauren TG",
        "8577 Schönholzerswilen",
        "8583 Götighofen",
        "8583 Donzhausen",
        "8583 Sulgen",
        "8584 Opfershofen TG",
        "8584 Leimbach TG",
        "8585 Klarsreuti",
        "8585 Happerswil",
        "8585 Birwinken",
        "8585 Mattwil",
        "8586 Kümmertshausen",
        "8586 Engishofen",
        "8586 Andwil TG",
        "8586 Erlen",
        "8586 Buchackern",
        "8586 Ennetaach",
        "8586 Buch b. Kümmertshausen",
        "8586 Riedt b. Erlen",
        "8588 Zihlschlacht",
        "8589 Sitterdorf",
        "9213 Hauptwil",
        "9214 Kradolf",
        "9215 Buhwil",
        "9215 Schönenberg an der Thur",
        "9216 Heldswil",
        "9216 Hohentannen",
        "9217 Neukirch an der Thur",
        "9220 Bischofszell",
        "9223 Schweizersholz",
        "9223 Halden",
        "9225 Wilen (Gottshaus)",
        "9225 St. Pelagiberg",
        "9503 Lanterswil",
        "9503 Stehrenberg",
        "9504 Friltschen",
        "9514 Wuppenau",
        "9515 Hosenruck",
        "9517 Mettlen",
        "9556 Affeltrangen",
        "9556 Zezikon",
        "9562 Märwil",
        "9562 Buch b. Märwil",
        "9565 Schmidshof",
        "9565 Bussnang",
        "9565 Rothenhausen",
        "9565 Oppikon",
        "9565 Oberbussnang",
        "6500 Bellinzona",
        "6503 Bellinzona",
        "6512 Giubiasco",
        "6513 Monte Carasso",
        "6514 Sementina",
        "6515 Gudo",
        "6517 Arbedo",
        "6518 Gorduno",
        "6523 Preonzo",
        "6524 Moleno",
        "6525 Gnosca",
        "6528 Camorino",
        "6532 Castione",
        "6533 Lumino",
        "6582 Pianezzo",
        "6583 S. Antonio (Val Morobbia)",
        "6584 Carena",
        "6592 S. Antonino",
        "6593 Cadenazzo",
        "6599 Robasacco",
        "6702 Claro",
        "6809 Medeglia",
        "6810 Isone",
        "6713 Malvaglia",
        "6714 Semione",
        "6715 Dongio",
        "6716 Leontica",
        "6716 Acquarossa",
        "6716 Lottigna",
        "6717 Torre",
        "6717 Dangio",
        "6718 Camperio",
        "6718 Olivone",
        "6719 Aquila",
        "6719 Aquila",
        "6719 Aquila",
        "6720 Campo (Blenio)",
        "6720 Ghirone",
        "6721 Ludiano",
        "6721 Motto (Blenio)",
        "6722 Corzoneso",
        "6723 Prugiasco",
        "6723 Marolta",
        "6723 Castro",
        "6724 Ponto Valentino",
        "6724 Largario",
        "6742 Pollegio",
        "6743 Bodio TI",
        "6744 Personico",
        "6745 Giornico",
        "6746 Lavorgo",
        "6746 Nivo",
        "6746 Calonico",
        "6747 Chironico",
        "6748 Anzonico",
        "6749 Cavagnago",
        "6749 Sobrio",
        "6760 Campello",
        "6760 Carì",
        "6760 Calpiogna",
        "6760 Rossura",
        "6760 Faido",
        "6760 Molare",
        "6763 Mairengo",
        "6763 Osco",
        "6764 Chiggiogna",
        "6764 Chiggiogna",
        "6772 Rodi-Fiesso",
        "6773 Prato (Leventina)",
        "6774 Dalpe",
        "6775 Ambrì",
        "6776 Piotta",
        "6777 Quinto",
        "6777 Varenzo",
        "6780 Madrano",
        "6780 Airolo",
        "6781 Villa Bedretto",
        "6781 Bedretto",
        "6516 Cugnasco",
        "6571 Indemini",
        "6572 Quartino",
        "6573 Magadino",
        "6574 Vira (Gambarogno)",
        "6575 S. Nazzaro",
        "6575 Vairano",
        "6576 Gerra (Gambarogno)",
        "6577 Ranzo",
        "6578 Caviano",
        "6579 Piazzogna",
        "6594 Contone",
        "6595 Riazzino",
        "6596 Gordola",
        "6597 Agarone",
        "6598 Tenero",
        "6600 Locarno",
        "6600 Muralto",
        "6600 Locarno",
        "6600 Solduno",
        "6605 Locarno",
        "6611 Gresso",
        "6611 Crana",
        "6611 Mosogno",
        "6612 Ascona",
        "6613 Porto Ronco",
        "6614 Isole di Brissago",
        "6614 Brissago",
        "6616 Losone",
        "6618 Arcegno",
        "6622 Ronco sopra Ascona",
        "6631 Corippo",
        "6632 Vogorno",
        "6633 Lavertezzo",
        "6634 Brione (Verzasca)",
        "6635 Gerra (Verzasca)",
        "6636 Frasco",
        "6637 Sonogno",
        "6644 Orselina",
        "6645 Brione sopra Minusio",
        "6646 Contra",
        "6647 Mergoscia",
        "6648 Minusio",
        "6652 Tegna",
        "6653 Verscio",
        "6654 Cavigliano",
        "6655 Verdasio",
        "6655 Intragna",
        "6655 Rasa",
        "6656 Golino",
        "6657 Palagnedra",
        "6658 Borgnone",
        "6659 Camedo",
        "6659 Moneto",
        "6661 Berzona",
        "6661 Auressio",
        "6661 Loco",
        "6662 Russo",
        "6663 Spruga",
        "6663 Comologno",
        "6664 Vergeletto",
        "6802 Rivera",
        "6803 Camignolo",
        "6804 Bironico",
        "6805 Mezzovico",
        "6805 Mezzovico",
        "6806 Sigirino",
        "6807 Taverne",
        "6807 Taverne",
        "6808 Torricella",
        "6809 Medeglia",
        "6814 Cadempino",
        "6814 Lamone",
        "6815 Melide",
        "6816 Bissone",
        "6817 Maroggia",
        "6818 Melano",
        "6821 Rovio",
        "6822 Arogno",
        "6823 Pugerna",
        "6825 Capolago",
        "6827 Brusino Arsizio",
        "6867 Serpiano",
        "6900 Lugano",
        "6900 Paradiso",
        "6900 Massagno",
        "6912 Pazzallo",
        "6913 Carabbia",
        "6914 Carona",
        "6915 Pambio-Noranco",
        "6916 Grancia",
        "6917 Barbengo",
        "6918 Figino",
        "6919 Carabietta",
        "6921 Vico Morcote",
        "6922 Morcote",
        "6924 Sorengo",
        "6925 Gentilino",
        "6926 Montagnola",
        "6927 Agra",
        "6928 Manno",
        "6929 Gravesano",
        "6930 Bedano",
        "6932 Breganzona",
        "6933 Muzzano",
        "6934 Bioggio",
        "6935 Bosco Luganese",
        "6936 Cademario",
        "6937 Breno",
        "6938 Vezio",
        "6938 Fescoggia",
        "6939 Mugena",
        "6939 Arosio",
        "6942 Savosa",
        "6943 Vezia",
        "6944 Cureglia",
        "6945 Origlio",
        "6946 Ponte Capriasca",
        "6946 Ponte Capriasca",
        "6947 Vaglio",
        "6948 Porza",
        "6949 Comano",
        "6950 Tesserete",
        "6951 Signôra",
        "6951 Insone",
        "6951 Scareglia",
        "6951 Colla",
        "6951 Cozzo",
        "6951 Bogno",
        "6952 Canobbio",
        "6953 Lugaggia",
        "6954 Sala Capriasca",
        "6954 Bigorio",
        "6955 Oggio",
        "6955 Cagiallo",
        "6955 Oggio",
        "6956 Lopagno",
        "6957 Roveredo TI",
        "6958 Bidogno",
        "6958 Corticiasca",
        "6958 Corticiasca",
        "6959 Piandera Paese",
        "6959 Certara",
        "6959 Cimadera",
        "6959 Maglio di Colla",
        "6959 Curtina",
        "6959 Piandera Paese",
        "6960 Odogno",
        "6962 Viganello",
        "6963 Cureggia",
        "6963 Pregassona",
        "6964 Davesco-Soragno",
        "6965 Cadro",
        "6966 Villa Luganese",
        "6967 Dino",
        "6968 Sonvico",
        "6974 Aldesago",
        "6976 Castagnola",
        "6977 Ruvigliana",
        "6978 Gandria",
        "6979 Brè sopra Lugano",
        "6980 Castelrotto",
        "6981 Banco",
        "6981 Biogno-Beride",
        "6981 Bombinasco",
        "6981 Bedigliora",
        "6981 Bedigliora",
        "6982 Agno",
        "6983 Magliaso",
        "6984 Pura",
        "6986 Curio",
        "6986 Miglieglia",
        "6986 Novaggio",
        "6987 Caslano",
        "6988 Ponte Tresa",
        "6989 Purasca",
        "6990 Cassina d'Agno",
        "6991 Neggio",
        "6992 Vernate",
        "6992 Cimo",
        "6993 Iseo",
        "6994 Aranno",
        "6995 Molinazzo di Monteggio",
        "6995 Madonna del Piano",
        "6996 Ponte Cremenaga",
        "6997 Sessa",
        "6998 Termine",
        "6999 Astano",
        "6825 Capolago",
        "6826 Riva San Vitale",
        "6828 Balerna",
        "6830 Chiasso",
        "6832 Pedrinate",
        "6832 Seseglio",
        "6833 Vacallo",
        "6834 Morbio Inferiore",
        "6835 Morbio Superiore",
        "6837 Caneggio",
        "6837 Bruzella",
        "6838 Scudellate",
        "6838 Cabbio",
        "6838 Muggio",
        "6839 Sagno",
        "6850 Mendrisio",
        "6852 Genestrerio",
        "6853 Ligornetto",
        "6854 S. Pietro",
        "6855 Stabio",
        "6862 Rancate",
        "6863 Besazio",
        "6864 Arzo",
        "6865 Tremona",
        "6866 Meride",
        "6872 Salorino",
        "6872 Somazzo",
        "6873 Corteglia",
        "6874 Castel San Pietro",
        "6875 Campora",
        "6875 Casima",
        "6875 Monte",
        "6877 Coldrerio",
        "6883 Novazzano",
        "6526 Prosito",
        "6527 Lodrino",
        "6703 Osogna",
        "6705 Cresciano",
        "6707 Iragna",
        "6710 Biasca",
        "6670 Avegno",
        "6672 Gordevio",
        "6673 Maggia",
        "6674 Someo",
        "6674 Riveo",
        "6675 Cevio",
        "6676 Bignasco",
        "6677 Moghegno",
        "6677 Aurigeno",
        "6678 Giumaglio",
        "6678 Lodano",
        "6678 Coglio",
        "6682 Linescio",
        "6683 Cerentino",
        "6683 Niva (Vallemaggia)",
        "6684 Cimalmotto",
        "6684 Campo (Vallemaggia)",
        "6685 Bosco/Gurin",
        "6690 S. Carlo (Val Bavona)",
        "6690 Cavergno",
        "6692 Menzonio",
        "6692 Brontallo",
        "6693 Broglio",
        "6694 Prato-Sornico",
        "6695 Piano di Peccia",
        "6695 Peccia",
        "6696 Fusio",
        "6377 Seelisberg",
        "6441 Rütli",
        "6452 Sisikon",
        "6454 Flüelen",
        "6460 Altdorf UR",
        "6461 Isenthal",
        "6462 Seedorf UR",
        "6463 Bürglen UR",
        "6464 Spiringen",
        "6465 Unterschächen",
        "6466 Bauen",
        "6467 Schattdorf",
        "6468 Attinghausen",
        "6469 Haldi b. Schattdorf",
        "6472 Erstfeld",
        "6473 Silenen",
        "6474 Amsteg",
        "6475 Bristen",
        "6476 Intschi",
        "6482 Gurtnellen",
        "6484 Wassen UR",
        "6485 Meien",
        "6487 Göschenen",
        "6490 Andermatt",
        "6491 Realp",
        "6493 Hospental",
        "8751 Urnerboden",
        "1844 Villeneuve VD",
        "1845 Noville",
        "1846 Chessel",
        "1847 Rennaz",
        "1852 Roche VD",
        "1853 Yvorne",
        "1854 Leysin",
        "1856 Corbeyrier",
        "1860 Aigle",
        "1862 Les Mosses",
        "1862 La Comballaz",
        "1863 Le Sépey",
        "1864 Vers-l'Eglise",
        "1865 Les Diablerets",
        "1866 La Forclaz VD",
        "1867 Ollon VD",
        "1867 St-Triphon",
        "1867 Panex",
        "1880 Fenalet-sur-Bex",
        "1880 Bex",
        "1880 Les Plans-sur-Bex",
        "1880 Les Posses-sur-Bex",
        "1880 Frenières-sur-Bex",
        "1882 Gryon",
        "1884 Arveyes",
        "1884 Huémoz",
        "1884 Villars-sur-Ollon",
        "1885 Chesières",
        "1892 Morcles",
        "1892 Lavey-les-Bains",
        "1892 Lavey-Village",
        "1082 Corcelles-le-Jorat",
        "1085 Vulliens",
        "1088 Ropraz",
        "1509 Vucherens",
        "1510 Moudon",
        "1510 Syens",
        "1512 Chavannes-sur-Moudon",
        "1513 Rossenges",
        "1513 Hermenches",
        "1514 Bussy-sur-Moudon",
        "1515 Villars-le-Comte",
        "1521 Curtilles",
        "1522 Oulens-sur-Lucens",
        "1522 Lucens",
        "1523 Granges-près-Marnand",
        "1524 Marnand",
        "1525 Seigneux",
        "1525 Henniez",
        "1526 Forel-sur-Lucens",
        "1526 Cremin",
        "1530 Payerne",
        "1534 Sassel",
        "1535 Combremont-le-Grand",
        "1536 Combremont-le-Petit",
        "1537 Champtauroz",
        "1538 Treytorrens (Payerne)",
        "1543 Grandcour",
        "1545 Chevroux",
        "1551 Vers-chez-Perrin",
        "1552 Trey",
        "1554 Sédeilles",
        "1554 Rossens VD",
        "1555 Villarzel",
        "1562 Corcelles-près-Payerne",
        "1565 Missy",
        "1580 Avenches",
        "1580 Donatyre",
        "1580 Oleyres",
        "1584 Villars-le-Grand",
        "1585 Cotterd",
        "1585 Salavaux",
        "1585 Bellerive VD",
        "1586 Vallamand",
        "1587 Montmagny",
        "1587 Constantine",
        "1588 Cudrefin",
        "1589 Chabrey",
        "1595 Faoug",
        "1682 Dompierre VD",
        "1682 Lovatens",
        "1682 Villars-Bramard",
        "1682 Prévonloup",
        "1682 Cerniaz VD",
        "1683 Chesalles-sur-Moudon",
        "1683 Brenles",
        "1683 Sarzens",
        "1787 Mur (Vully) VD",
        "1031 Mex VD",
        "1034 Boussens",
        "1035 Bournens",
        "1036 Sullens",
        "1037 Etagnières",
        "1038 Bercher",
        "1040 Echallens",
        "1040 Villars-le-Terroir",
        "1040 St-Barthélemy VD",
        "1041 Poliez-Pittet",
        "1041 Poliez-le-Grand",
        "1041 Dommartin",
        "1041 Bottens",
        "1041 Montaubion-Chardonney",
        "1041 Naz",
        "1042 Bettens",
        "1042 Assens",
        "1042 Bioley-Orjulaz",
        "1043 Sugnens",
        "1044 Fey",
        "1045 Ogens",
        "1046 Rueyres",
        "1047 Oppens",
        "1053 Bretigny-sur-Morrens",
        "1053 Cugy VD",
        "1054 Morrens VD",
        "1055 Froideville",
        "1058 Villars-Tiercelin",
        "1059 Peney-le-Jorat",
        "1061 Villars-Mendraz",
        "1062 Sottens",
        "1063 Peyres-Possens",
        "1063 Chapelle-sur-Moudon",
        "1063 Boulens",
        "1063 Martherenges",
        "1302 Vufflens-la-Ville",
        "1303 Penthaz",
        "1305 Penthalaz",
        "1306 Daillens",
        "1307 Lussery-Villars",
        "1375 Penthéréaz",
        "1376 Goumoens-le-Jux",
        "1376 Goumoens-la-Ville",
        "1376 Eclagnens",
        "1377 Oulens-sous-Echallens",
        "1409 Chanéaz",
        "1410 Thierrens",
        "1410 Correvon",
        "1410 Denezy",
        "1410 St-Cierges",
        "1416 Pailly",
        "1417 Epautheyres",
        "1417 Essertines-sur-Yverdon",
        "1418 Vuarrens",
        "1515 Neyruz-sur-Moudon",
        "1148 La Praz",
        "1321 Arnex-sur-Orbe",
        "1322 Croy",
        "1323 Romainmôtier",
        "1324 Premier",
        "1325 Vaulion",
        "1326 Juriens",
        "1329 Bretonnières",
        "1337 Vallorbe",
        "1338 Ballaigues",
        "1341 Orient",
        "1342 Le Pont",
        "1343 Les Charbonnières",
        "1344 L'Abbaye",
        "1345 Le Lieu",
        "1345 Le Séchey",
        "1346 Les Bioux",
        "1347 Le Sentier",
        "1347 Le Solliat",
        "1348 Le Brassus",
        "1350 Orbe",
        "1352 Agiez",
        "1353 Bofflens",
        "1354 Montcherand",
        "1355 L'Abergement",
        "1355 Sergey",
        "1356 La Russille",
        "1356 Les Clées",
        "1357 Lignerolle",
        "1358 Valeyres-sous-Rances",
        "1372 Bavois",
        "1373 Chavornay",
        "1374 Corcelles-sur-Chavornay",
        "1400 Cheseaux-Noréaz",
        "1400 Yverdon-les-Bains",
        "1404 Cuarny",
        "1404 Villars-Epeney",
        "1405 Pomy",
        "1406 Cronay",
        "1407 Donneloye",
        "1407 Gossens",
        "1407 Bioley-Magnoux",
        "1407 Mézery-près-Donneloye",
        "1408 Prahins",
        "1412 Ursins",
        "1412 Valeyres-sous-Ursins",
        "1413 Orzens",
        "1415 Molondin",
        "1415 Démoret",
        "1420 Fiez",
        "1421 Grandevent",
        "1421 Fontaines-sur-Grandson",
        "1422 Grandson",
        "1423 Villars-Burquin",
        "1423 Romairon",
        "1423 Fontanezier",
        "1423 Vaugondry",
        "1424 Champagne",
        "1425 Onnens VD",
        "1426 Corcelles-près-Concise",
        "1426 Concise",
        "1427 Bonvillars",
        "1428 Provence",
        "1428 Mutrux",
        "1429 Giez",
        "1430 Orges",
        "1431 Vugelles-La Mothe",
        "1431 Novalles",
        "1432 Gressy",
        "1432 Belmont-sur-Yverdon",
        "1433 Suchy",
        "1434 Ependes VD",
        "1435 Essert-Pittet",
        "1436 Chamblon",
        "1436 Treycovagnes",
        "1437 Suscévaz",
        "1438 Mathod",
        "1439 Rances",
        "1441 Valeyres-sous-Montagny",
        "1442 Montagny-près-Yverdon",
        "1443 Villars-sous-Champvent",
        "1443 Champvent",
        "1443 Essert-sous-Champvent",
        "1445 Vuiteboeuf",
        "1446 Baulmes",
        "1450 Le Château-de-Ste-Croix",
        "1450 Ste-Croix",
        "1450 La Sagne (Ste-Croix)",
        "1452 Les Rasses",
        "1453 Mauborget",
        "1453 Bullet",
        "1454 L'Auberson",
        "1454 La Vraconnaz",
        "1462 Yvonand",
        "1463 Rovray",
        "1464 Chêne-Pâquier",
        "1464 Chavannes-le-Chêne",
        "1000 Lausanne 26",
        "1000 Lausanne 27",
        "1000 Lausanne 25",
        "1003 Lausanne",
        "1004 Lausanne",
        "1005 Lausanne",
        "1006 Lausanne",
        "1007 Lausanne",
        "1008 Jouxtens-Mézery",
        "1010 Lausanne",
        "1011 Lausanne",
        "1012 Lausanne",
        "1015 Lausanne",
        "1018 Lausanne",
        "1032 Romanel-sur-Lausanne",
        "1033 Cheseaux-sur-Lausanne",
        "1052 Le Mont-sur-Lausanne",
        "1066 Epalinges",
        "1009 Pully",
        "1068 Les Monts-de-Pully",
        "1070 Puidoux",
        "1071 St-Saphorin (Lavaux)",
        "1071 Chexbres",
        "1071 Rivaz",
        "1072 Forel (Lavaux)",
        "1073 Mollie-Margot",
        "1073 Savigny",
        "1076 Ferlens VD",
        "1077 Servion",
        "1078 Essertes",
        "1080 Les Cullayes",
        "1081 Montpreveyres",
        "1083 Mézières VD",
        "1084 Carrouge VD",
        "1090 La Croix (Lutry)",
        "1091 Grandvaux",
        "1091 Aran",
        "1091 Chenaux",
        "1092 Belmont-sur-Lausanne",
        "1093 La Conversion",
        "1094 Paudex",
        "1095 Lutry",
        "1096 Cully",
        "1096 Villette (Lavaux)",
        "1097 Riex",
        "1098 Epesses",
        "1607 Palézieux-Village",
        "1607 Les Tavernes",
        "1607 Palézieux",
        "1607 Les Thioleyres",
        "1608 Oron-le-Châtel",
        "1608 Chesalles-sur-Oron",
        "1608 Bussigny-sur-Oron",
        "1610 Vuibroye",
        "1610 Châtillens",
        "1610 Oron-la-Ville",
        "1612 Ecoteaux",
        "1613 Maracon",
        "1026 Echandens",
        "1026 Denges",
        "1027 Lonay",
        "1028 Préverenges",
        "1110 Morges",
        "1112 Echichens",
        "1113 St-Saphorin-sur-Morges",
        "1114 Colombier VD",
        "1115 Vullierens",
        "1116 Cottens VD",
        "1117 Grancy",
        "1121 Bremblens",
        "1122 Romanel-sur-Morges",
        "1123 Aclens",
        "1124 Gollion",
        "1125 Monnaz",
        "1126 Vaux-sur-Morges",
        "1127 Clarmont",
        "1128 Reverolle",
        "1131 Tolochenaz",
        "1132 Lully VD",
        "1134 Chigny",
        "1134 Vufflens-le-Château",
        "1135 Denens",
        "1136 Bussy-Chardonney",
        "1141 Sévery",
        "1142 Pampigny",
        "1143 Apples",
        "1144 Ballens",
        "1145 Bière",
        "1146 Mollens VD",
        "1147 Montricher",
        "1148 La Coudre",
        "1148 Chavannes-le-Veyron",
        "1148 Mauraz",
        "1148 Villars-Bozon",
        "1148 Mont-la-Ville",
        "1148 Moiry VD",
        "1148 Cuarnens",
        "1148 L'Isle",
        "1149 Berolle",
        "1162 St-Prex",
        "1163 Etoy",
        "1164 Buchillon",
        "1165 Allaman",
        "1167 Lussy-sur-Morges",
        "1168 Villars-sous-Yens",
        "1169 Yens",
        "1170 Aubonne",
        "1172 Bougy-Villars",
        "1173 Féchy",
        "1174 Pizy",
        "1174 Montherod",
        "1175 Lavigny",
        "1176 St-Livres",
        "1187 St-Oyens",
        "1188 Gimel",
        "1189 Saubraz",
        "1304 Dizy",
        "1304 Cossonay-Ville",
        "1304 Allens",
        "1304 Senarclens",
        "1308 La Chaux (Cossonay)",
        "1312 Eclépens",
        "1313 Ferreyres",
        "1315 La Sarraz",
        "1316 Chevilly",
        "1317 Orny",
        "1318 Pompaples",
        "1166 Perroy",
        "1180 Tartegnin",
        "1180 Rolle",
        "1182 Gilly",
        "1183 Bursins",
        "1184 Vinzel",
        "1184 Luins",
        "1185 Mont-sur-Rolle",
        "1186 Essertines-sur-Rolle",
        "1188 St-George",
        "1195 Bursinel",
        "1195 Dully",
        "1196 Gland",
        "1197 Prangins",
        "1260 Nyon",
        "1261 Marchissy",
        "1261 Le Vaud",
        "1261 Longirod",
        "1262 Eysins",
        "1263 Crassier",
        "1264 St-Cergue",
        "1265 La Cure",
        "1266 Duillier",
        "1267 Vich",
        "1267 Coinsins",
        "1268 Begnins",
        "1268 Burtigny",
        "1269 Bassins",
        "1270 Trélex",
        "1271 Givrins",
        "1272 Genolier",
        "1273 Arzier-Le Muids",
        "1274 Grens",
        "1274 Signy",
        "1275 Chéserex",
        "1276 Gingins",
        "1277 Arnex-sur-Nyon",
        "1277 Borex",
        "1278 La Rippe",
        "1279 Chavannes-de-Bogis",
        "1279 Bogis-Bossey",
        "1290 Chavannes-des-Bois",
        "1291 Commugny",
        "1295 Mies",
        "1295 Tannay",
        "1296 Coppet",
        "1297 Founex",
        "1299 Crans-près-Céligny",
        "1008 Prilly",
        "1020 Renens VD",
        "1022 Chavannes-près-Renens",
        "1023 Crissier",
        "1024 Ecublens VD",
        "1025 St-Sulpice VD",
        "1029 Villars-Ste-Croix",
        "1030 Bussigny",
        "1658 La Tine",
        "1658 Rossinière",
        "1659 Rougemont",
        "1659 Flendruz",
        "1660 Château-d'Oex",
        "1660 L'Etivaz",
        "1660 Les Moulins",
        "1660 La Lécherette",
        "1800 Vevey",
        "1801 Le Mont-Pèlerin",
        "1802 Corseaux",
        "1803 Chardonne",
        "1804 Corsier-sur-Vevey",
        "1805 Jongny",
        "1806 St-Légier-La Chiésaz",
        "1807 Blonay",
        "1808 Les Monts-de-Corsier",
        "1809 Fenil-sur-Corsier",
        "1814 La Tour-de-Peilz",
        "1815 Clarens",
        "1816 Chailly-Montreux",
        "1817 Brent",
        "1820 Territet",
        "1820 Veytaux",
        "1820 Montreux",
        "1822 Chernex",
        "1823 Glion",
        "1824 Caux",
        "1832 Villard-sur-Chamby",
        "1832 Chamby",
        "1833 Les Avants",
        "3900 Brig",
        "3900 Brigerbad",
        "3900 Gamsen",
        "3901 Rothwald",
        "3902 Glis",
        "3903 Birgisch",
        "3903 Mund",
        "3904 Naters",
        "3907 Gondo",
        "3907 Simplon Dorf",
        "3907 Gabi (Simplon)",
        "3911 Ried-Brig",
        "3912 Termen",
        "3913 Rosswald",
        "3914 Blatten b. Naters",
        "3914 Belalp",
        "3939 Eggerberg",
        "1911 Mayens-de-Chamoson",
        "1955 St-Pierre-de-Clages",
        "1955 Némiaz (Chamoson)",
        "1955 Les Vérines (Chamoson)",
        "1955 Chamoson",
        "1955 Grugnay (Chamoson)",
        "1957 Ardon",
        "1963 Vétroz",
        "1964 Conthey",
        "1975 St-Séverin",
        "1976 Aven",
        "1976 Erde",
        "1976 Daillon",
        "1993 Clèbes (Nendaz)",
        "1994 Aproz (Nendaz)",
        "1996 Beuson (Nendaz)",
        "1996 Condémines (Nendaz)",
        "1996 Brignon (Nendaz)",
        "1996 Baar (Nendaz)",
        "1996 Basse-Nendaz",
        "1996 Bieudron (Nendaz)",
        "1996 Saclentse",
        "1996 Fey (Nendaz)",
        "1997 Siviez (Nendaz)",
        "1997 Sornard (Nendaz)",
        "1997 Haute-Nendaz",
        "1927 Chemin",
        "1933 Sembrancher",
        "1933 Vens (Sembrancher)",
        "1933 La Garde (Sembrancher)",
        "1933 Chamoille (Sembrancher)",
        "1934 Le Châble VS",
        "1934 Bruson",
        "1936 Verbier",
        "1937 Orsières",
        "1938 Champex-Lac",
        "1941 Vollèges",
        "1941 Cries (Vollèges)",
        "1942 Levron",
        "1943 Praz-de-Fort",
        "1944 La Fouly VS",
        "1945 Les Moulins VS (Liddes)",
        "1945 Liddes",
        "1945 Petit Vichères (Liddes)",
        "1945 Vichères (Liddes)",
        "1945 Fontaine Dessus (Liddes)",
        "1945 Fontaine Dessous (Liddes)",
        "1945 Rive Haute (Liddes)",
        "1945 Palasuit (Liddes)",
        "1945 Chandonne (Liddes)",
        "1945 Dranse (Liddes)",
        "1945 Chez Petit (Liddes)",
        "1945 Fornex (Liddes)",
        "1946 Bourg-St-Pierre",
        "1947 Champsec",
        "1947 Versegères",
        "1948 Lourtier",
        "1948 Fionnay",
        "1948 Sarreyer",
        "3801 Jungfraujoch",
        "3984 Fieschertal",
        "3984 Fiesch",
        "3984 Fiesch",
        "3985 Münster VS",
        "3985 Geschinen",
        "3988 Ulrichen",
        "3988 Obergesteln",
        "3989 Ritzingen",
        "3989 Blitzingen",
        "3989 Selkingen",
        "3989 Biel VS",
        "3989 Niederwald",
        "3994 Lax",
        "3995 Steinhaus",
        "3995 Mühlebach (Goms)",
        "3995 Ausserbinn",
        "3995 Steinhaus",
        "3995 Mühlebach (Goms)",
        "3995 Ernen",
        "3996 Binn",
        "3997 Bellwald",
        "3998 Gluringen",
        "3998 Reckingen VS",
        "3999 Oberwald",
        "1961 Vernamiège",
        "1966 St-Romain (Ayent)",
        "1966 Fortunau (Ayent)",
        "1966 Villa (Ayent)",
        "1966 La Place (Ayent)",
        "1966 Blignou (Ayent)",
        "1966 Luc (Ayent)",
        "1966 Signèse (Ayent)",
        "1966 Saxonne (Ayent)",
        "1966 Argnou (Ayent)",
        "1966 Botyre (Ayent)",
        "1968 Mase",
        "1969 Trogne (St-Martin)",
        "1969 Liez (St-Martin)",
        "1969 Eison (St-Martin)",
        "1969 St-Martin VS",
        "1969 Suen (St-Martin)",
        "1972 Anzère",
        "1973 Nax",
        "1981 Vex",
        "1982 Euseigne",
        "1983 Evolène",
        "1983 Lanna",
        "1984 La Tour VS",
        "1984 Les Haudères",
        "1985 Villa (Evolène)",
        "1985 La Sage",
        "1985 La Forclaz VS",
        "1986 Arolla",
        "1987 Hérémence",
        "1988 Thyon",
        "1988 Les Collons",
        "3945 Niedergampel",
        "3945 Gampel",
        "3946 Gruben",
        "3946 Turtmann",
        "3947 Ergisch",
        "3948 Oberems",
        "3948 Unterems",
        "3951 Agarn",
        "3952 Susten",
        "3953 Inden",
        "3953 Leuk Stadt",
        "3953 Varen",
        "3954 Leukerbad",
        "3955 Albinen",
        "3956 Guttet-Feschel",
        "3957 Erschmatt",
        "3957 Bratsch",
        "3970 Salgesch",
        "1906 Charrat",
        "1907 Saxon",
        "1908 Riddes",
        "1911 Ovronnaz",
        "1912 Montagnon (Leytron)",
        "1912 Leytron",
        "1912 Dugny (Leytron)",
        "1912 Produit (Leytron)",
        "1913 Saillon",
        "1913 Saillon",
        "1914 Auddes-sur-Riddes",
        "1914 Isérables",
        "1918 La Tzoumaz",
        "1920 Martigny",
        "1921 Martigny-Croix",
        "1926 Fully",
        "1928 Ravoire",
        "1929 Trient",
        "1932 Les Valettes (Bovernier)",
        "1932 Bovernier",
        "1868 Collombey",
        "1870 Monthey",
        "1870 Monthey",
        "1871 Choëx",
        "1871 Les Giettes",
        "1872 Troistorrents",
        "1873 Val-d'Illiez",
        "1873 Champoussin",
        "1873 Les Crosets",
        "1874 Champéry",
        "1875 Morgins",
        "1893 Muraz (Collombey)",
        "1895 Vionnaz",
        "1896 Vouvry",
        "1896 Miex",
        "1897 Bouveret",
        "1897 Les Evouettes",
        "1898 St-Gingolph",
        "1899 Torgon",
        "3916 Ferden",
        "3917 Goppenstein",
        "3917 Kippel",
        "3918 Wiler (Lötschen)",
        "3919 Blatten (Lötschen)",
        "3935 Bürchen",
        "3938 Ausserberg",
        "3940 Steg VS",
        "3942 Niedergesteln",
        "3942 St. German",
        "3942 Raron",
        "3943 Eischoll",
        "3944 Unterbäch VS",
        "3949 Hohtenn",
        "3982 Bitsch",
        "3983 Filet",
        "3983 Goppisberg",
        "3983 Bister",
        "3983 Greich",
        "3983 Mörel",
        "3986 Ried-Mörel",
        "3987 Riederalp",
        "3991 Betten",
        "3992 Bettmeralp",
        "3993 Grengiols",
        "3994 Martisberg",
        "1869 Massongex",
        "1890 St-Maurice",
        "1890 Mex VS",
        "1891 Vérossaz",
        "1902 Evionnaz",
        "1903 Collonges",
        "1904 Vernayaz",
        "1905 Dorénaz",
        "1922 Les Granges (Salvan)",
        "1922 Salvan",
        "1923 Le Trétien",
        "1923 Les Marécottes",
        "1925 Finhaut",
        "1925 Le Châtelard VS",
        "1958 St-Léonard",
        "1977 Icogne",
        "1978 Lens",
        "3960 Niouc",
        "3960 Sierre",
        "3960 Corin-de-la-Crête",
        "3960 Loc",
        "3960 Muraz (Sierre)",
        "3961 Mission",
        "3961 St-Jean VS",
        "3961 Vissoie",
        "3961 Zinal",
        "3961 St-Luc",
        "3961 Grimentz",
        "3961 Ayer",
        "3961 Chandolin",
        "3963 Crans-Montana",
        "3963 Montana",
        "3963 Aminona",
        "3965 Chippis",
        "3966 Réchy",
        "3966 Chalais",
        "3967 Vercorin",
        "3968 Veyras",
        "3971 Ollon VS",
        "3971 Chermignon-d'en-Bas",
        "3971 Chermignon",
        "3972 Miège",
        "3973 Venthône",
        "3974 Mollens VS",
        "3975 Randogne",
        "3976 Champzabé",
        "3976 Noës",
        "3977 Granges VS",
        "3978 Flanthey",
        "3979 Grône",
        "1950 Sion",
        "1958 Uvrier",
        "1962 Pont-de-la-Morge (Sion)",
        "1965 Chandolin (Savièse)",
        "1965 Monteiller (Savièse)",
        "1965 Drône (Savièse)",
        "1965 St-Germain (Savièse)",
        "1965 Ormône (Savièse)",
        "1965 Diolly (Savièse)",
        "1965 Granois (Savièse)",
        "1965 Roumaz (Savièse)",
        "1965 Mayens-de-la-Zour (Savièse)",
        "1965 La Muraz (Savièse)",
        "1967 Bramois",
        "1971 Grimisuat",
        "1971 Champlan (Grimisuat)",
        "1974 Arbaz",
        "1991 Misériez (Salins)",
        "1991 Arvillard (Salins)",
        "1991 Pravidondaz (Salins)",
        "1991 Turin (Salins)",
        "1991 Salins",
        "1992 La Vernaz (Les Agettes)",
        "1992 Crête-à-l'Oeil (Les Agettes)",
        "1992 Les Agettes",
        "1992 Les Mayens-de-Sion",
        "1993 Veysonnaz",
        "3905 Saas-Almagell",
        "3906 Saas-Fee",
        "3908 Saas-Balen",
        "3910 Saas-Grund",
        "3920 Zermatt",
        "3922 Kalpetran",
        "3922 Stalden VS",
        "3922 Eisten",
        "3923 Törbel",
        "3924 St. Niklaus VS",
        "3925 Grächen",
        "3926 Embd",
        "3927 Herbriggen",
        "3928 Randa",
        "3929 Täsch",
        "3930 Eyholz",
        "3930 Visp",
        "3931 Lalden",
        "3932 Visperterminen",
        "3933 Staldenried",
        "3934 Zeneggen",
        "3937 Baltschieder",
        "3937 Baltschieder",
        "6300 Zugerberg",
        "6300 Zug",
        "6312 Steinhausen",
        "6313 Menzingen",
        "6313 Edlibach",
        "6313 Finstersee",
        "6314 Neuägeri",
        "6314 Unterägeri",
        "6315 Morgarten",
        "6315 Alosen",
        "6315 Oberägeri",
        "6317 Oberwil b. Zug",
        "6318 Walchwil",
        "6319 Allenwinden",
        "6330 Cham",
        "6331 Hünenberg",
        "6332 Hagendorn",
        "6333 Hünenberg See",
        "6340 Sihlbrugg",
        "6340 Baar",
        "6343 Risch",
        "6343 Buonas",
        "6343 Rotkreuz",
        "6343 Holzhäusern ZG",
        "6345 Neuheim",
        "8143 Stallikon",
        "8143 Uetliberg",
        "8906 Bonstetten",
        "8907 Wettswil",
        "8908 Hedingen",
        "8909 Zwillikon",
        "8910 Affoltern am Albis",
        "8911 Rifferswil",
        "8912 Obfelden",
        "8913 Ottenbach",
        "8914 Aeugstertal",
        "8914 Aeugst am Albis",
        "8915 Hausen am Albis",
        "8925 Ebertswil",
        "8926 Hauptikon",
        "8926 Kappel am Albis",
        "8926 Uerzlikon",
        "8932 Mettmenstetten",
        "8933 Maschwanden",
        "8934 Knonau",
        "8212 Nohl",
        "8245 Feuerthalen",
        "8246 Langwiesen",
        "8247 Flurlingen",
        "8248 Uhwiesen",
        "8414 Buch am Irchel",
        "8415 Berg am Irchel",
        "8415 Gräslikon",
        "8416 Flaach",
        "8444 Henggart",
        "8447 Dachsen",
        "8450 Andelfingen",
        "8451 Kleinandelfingen",
        "8452 Adlikon b. Andelfingen",
        "8453 Alten",
        "8457 Humlikon",
        "8458 Dorf",
        "8459 Volken",
        "8460 Marthalen",
        "8461 Oerlingen",
        "8462 Rheinau",
        "8463 Benken ZH",
        "8464 Ellikon am Rhein",
        "8465 Rudolfingen",
        "8465 Wildensbuch",
        "8466 Trüllikon",
        "8467 Truttikon",
        "8468 Guntalingen",
        "8468 Waltalingen",
        "8475 Ossingen",
        "8476 Unterstammheim",
        "8477 Oberstammheim",
        "8478 Thalheim an der Thur",
        "8152 Glattbrugg",
        "8152 Glattpark (Opfikon)",
        "8152 Opfikon",
        "8180 Bülach",
        "8181 Höri",
        "8182 Hochfelden",
        "8184 Bachenbülach",
        "8185 Winkel",
        "8192 Glattfelden",
        "8192 Zweidlen",
        "8193 Eglisau",
        "8194 Hüntwangen",
        "8195 Wasterkingen",
        "8196 Wil ZH",
        "8197 Rafz",
        "8302 Kloten",
        "8303 Bassersdorf",
        "8304 Wallisellen",
        "8305 Dietlikon",
        "8309 Nürensdorf",
        "8424 Embrach",
        "8425 Oberembrach",
        "8426 Lufingen",
        "8427 Freienstein",
        "8427 Rorbas",
        "8428 Teufen ZH",
        "8105 Watt",
        "8105 Regensdorf",
        "8106 Adlikon b. Regensdorf",
        "8107 Buchs ZH",
        "8108 Dällikon",
        "8112 Otelfingen",
        "8113 Boppelsen",
        "8114 Dänikon ZH",
        "8115 Hüttikon",
        "8153 Rümlang",
        "8154 Oberglatt ZH",
        "8155 Niederhasli",
        "8155 Nassenwil",
        "8156 Oberhasli",
        "8157 Dielsdorf",
        "8158 Regensberg",
        "8162 Steinmaur",
        "8162 Sünikon",
        "8164 Bachs",
        "8165 Oberweningen",
        "8165 Schöfflisdorf",
        "8165 Schleinikon",
        "8166 Niederweningen",
        "8172 Niederglatt ZH",
        "8173 Neerach",
        "8174 Stadel b. Niederglatt",
        "8175 Windlach",
        "8187 Weiach",
        "8340 Hinwil",
        "8342 Wernetshausen",
        "8344 Bäretswil",
        "8345 Adetswil",
        "8496 Steg im Tösstal",
        "8497 Fischenthal",
        "8498 Gibswil",
        "8607 Aathal-Seegräben",
        "8608 Bubikon",
        "8614 Bertschikon (Gossau ZH)",
        "8620 Wetzikon ZH",
        "8623 Wetzikon ZH",
        "8624 Grüt (Gossau ZH)",
        "8625 Gossau ZH",
        "8626 Ottikon (Gossau ZH)",
        "8627 Grüningen",
        "8630 Rüti ZH",
        "8632 Tann",
        "8633 Wolfhausen",
        "8635 Dürnten",
        "8636 Wald ZH",
        "8637 Laupen ZH",
        "8134 Adliswil",
        "8135 Sihlwald",
        "8135 Langnau am Albis",
        "8135 Sihlbrugg Station",
        "8136 Gattikon",
        "8800 Thalwil",
        "8802 Kilchberg ZH",
        "8803 Rüschlikon",
        "8804 Au ZH",
        "8805 Richterswil",
        "8810 Horgen",
        "8815 Horgenberg",
        "8816 Hirzel",
        "8820 Wädenswil",
        "8824 Schönenberg ZH",
        "8825 Hütten",
        "8833 Samstagern",
        "8942 Oberrieden",
        "8125 Zollikerberg",
        "8126 Zumikon",
        "8127 Forch",
        "8618 Oetwil am See",
        "8634 Hombrechtikon",
        "8700 Küsnacht ZH",
        "8702 Zollikon",
        "8703 Erlenbach ZH",
        "8704 Herrliberg",
        "8706 Meilen",
        "8707 Uetikon am See",
        "8708 Männedorf",
        "8712 Stäfa",
        "8713 Uerikon",
        "8714 Feldbach",
        "8307 Ottikon b. Kemptthal",
        "8307 Effretikon",
        "8308 Illnau",
        "8308 Agasul",
        "8310 Kemptthal",
        "8310 Grafstal",
        "8312 Winterberg ZH",
        "8314 Kyburg",
        "8315 Lindau",
        "8317 Tagelswangen",
        "8320 Fehraltorf",
        "8322 Madetswil",
        "8322 Gündisau",
        "8330 Pfäffikon ZH",
        "8331 Auslikon",
        "8332 Rumlikon",
        "8332 Russikon",
        "8335 Hittnau",
        "8484 Theilingen",
        "8484 Weisslingen",
        "8484 Neschwil",
        "8489 Ehrikon",
        "8489 Wildberg",
        "8489 Schalchen",
        "8492 Wila",
        "8493 Saland",
        "8494 Bauma",
        "8499 Sternenberg",
        "8044 Gockhausen",
        "8117 Fällanden",
        "8118 Pfaffhausen",
        "8121 Benglen",
        "8122 Binz",
        "8123 Ebmatingen",
        "8124 Maur",
        "8132 Hinteregg",
        "8132 Egg b. Zürich",
        "8133 Esslingen",
        "8306 Brüttisellen",
        "8600 Dübendorf",
        "8602 Wangen b. Dübendorf",
        "8603 Schwerzenbach",
        "8604 Volketswil",
        "8605 Gutenswil",
        "8606 Nänikon",
        "8606 Greifensee",
        "8610 Uster",
        "8614 Sulzbach",
        "8615 Wermatswil",
        "8615 Freudwil",
        "8616 Riedikon",
        "8617 Mönchaltorf",
        "8311 Brütten",
        "8352 Ricketwil (Winterthur)",
        "8352 Elsau",
        "8353 Elgg",
        "8354 Hofstetten ZH",
        "8400 Winterthur",
        "8404 Stadel (Winterthur)",
        "8404 Reutlingen (Winterthur)",
        "8404 Winterthur",
        "8405 Winterthur",
        "8406 Winterthur",
        "8408 Winterthur",
        "8409 Winterthur",
        "8412 Aesch (Neftenbach)",
        "8412 Riet (Neftenbach)",
        "8412 Hünikon (Neftenbach)",
        "8413 Neftenbach",
        "8418 Schlatt ZH",
        "8421 Dättlikon",
        "8422 Pfungen",
        "8442 Hettlingen",
        "8471 Dägerlen",
        "8471 Rutschwil (Dägerlen)",
        "8471 Bänk (Dägerlen)",
        "8471 Oberwil (Dägerlen)",
        "8471 Berg (Dägerlen)",
        "8472 Seuzach",
        "8474 Dinhard",
        "8479 Altikon",
        "8482 Sennhof (Winterthur)",
        "8483 Kollbrunn",
        "8486 Rikon im Tösstal",
        "8487 Rämismühle",
        "8487 Zell ZH",
        "8488 Turbenthal",
        "8495 Schmidrüti",
        "8523 Hagenbuch ZH",
        "8542 Wiesendangen",
        "8543 Gundetswil",
        "8543 Kefikon ZH",
        "8543 Bertschikon",
        "8544 Attikon",
        "8545 Rickenbach Sulz",
        "8545 Rickenbach ZH",
        "8546 Menzengrüt",
        "8548 Ellikon an der Thur",
        "8102 Oberengstringen",
        "8103 Unterengstringen",
        "8104 Weiningen ZH",
        "8142 Uitikon Waldegg",
        "8902 Urdorf",
        "8903 Birmensdorf ZH",
        "8904 Aesch ZH",
        "8951 Fahrweid",
        "8952 Schlieren",
        "8953 Dietikon",
        "8954 Geroldswil",
        "8955 Oetwil an der Limmat",
        "8001 Zürich",
        "8002 Zürich",
        "8003 Zürich",
        "8004 Zürich",
        "8005 Zürich",
        "8006 Zürich",
        "8008 Zürich",
        "8032 Zürich",
        "8037 Zürich",
        "8038 Zürich",
        "8041 Zürich",
        "8044 Zürich",
        "8045 Zürich",
        "8046 Zürich",
        "8047 Zürich",
        "8048 Zürich",
        "8049 Zürich",
        "8050 Zürich",
        "8051 Zürich",
        "8052 Zürich",
        "8053 Zürich",
        "8055 Zürich",
        "8057 Zürich",
        "8064 Zürich"
    ]
}