import * as React from 'react';
import { StyleSheet, View } from 'react-native';

export const Separator = () => (
    <View style={styles.separator} />
  );
  
export const pickerStyles = {
    option: (provided, state) => ({
      ...provided,
      fontFamily: "SourceSansPro_400Regular",
    }),
    control: base => ({
      ...base,
      fontFamily: "SourceSansPro_400Regular",
       }),
    menu: base => ({
    ...base,
    fontFamily: "SourceSansPro_400Regular",
      })
  }
  
  
  
export const styles = StyleSheet.create({
    body: {
      fontFamily: "SourceSansPro_300Light",
      lineHeight: 25,
      color: "#222222",
      fontSize: 20  
    },
    boldBody: {
      fontFamily: "SourceSansPro_700Bold",
      lineHeight: 20,
      color: "#262626",
      fontSize: 16  
    },
    ordered_list: {
      fontFamily: "SourceSansPro_300Light",
      lineHeight: 25,
      color: "#001100",
      fontSize: 20  
    },
    smallBody: {
      fontFamily: "SourceSansPro_300Light",
      lineHeight: 20,
      color: "#262626",
      fontSize: 16 
    },
    heading1: {
      fontFamily: "SourceSansPro_400Regular",
      lineHeight: 35,
      color: "#363636",
      fontSize: 36  
    },
    heading2: {
      fontFamily: "SourceSansPro_400Regular",
      lineHeight: 35,
      color: "#363636",
      fontSize: 30  
    },
    separator: {
      marginVertical: 8,
      borderBottomColor: '#737373',
      borderBottomWidth: StyleSheet.hairlineWidth,
    },
    
  });