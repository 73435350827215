import * as React from 'react';
import { View } from 'react-native';
import '@expo/match-media';
import { useMediaQuery } from 'react-responsive';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Appbar, ActivityIndicator, Provider, DefaultTheme, DarkTheme, configureFonts, Text} from 'react-native-paper';
import {useFonts, SourceSansPro_700Bold, SourceSansPro_600SemiBold, SourceSansPro_300Light, SourceSansPro_400Regular} from '@expo-google-fonts/source-sans-pro';
import Toast from "react-native-fast-toast";
import * as Localization from 'expo-localization';

import {styles} from './Styles'
import {params} from './Params'
import {strings} from './Strings'
import {TabManager} from './TabManager'

const linking = {
  config: {
    "Goods2Give: Home": "",
    "Goods2Give: About": "about",
  },
};
export default function App() {

  const [language, setLanguage] = React.useState(Localization.locale.startsWith("de") ? "DE":"EN")


  const isTabletOrMobileDevice = useMediaQuery({"query" : "(max-device-width: 1224px)"});
  const containerStyle = isTabletOrMobileDevice ? 
    {
      flex: 1,
      justifyContent: 'center',
      backgroundColor: '#e0f0f1',
      //paddingBottom: '1% !important',
      //borderColor: 'black'
    } : {
      marginHorizontal: '15%',
      flex: 1,
      justifyContent: 'center',
      backgroundColor: '#e0f0f1',
      //paddingBottom: '1% !important',
      //borderColor: 'black'
    }
  const Lang = (ln) =>  ({size}) => (<Text style={ language===ln ? styles.boldBody :  styles.smallBody} > {ln} </Text>)

  let [fontsLoaded] = useFonts({
    SourceSansPro_700Bold, SourceSansPro_300Light, SourceSansPro_600SemiBold, SourceSansPro_400Regular
  });
  if (!fontsLoaded)
    return <ActivityIndicator size={180} />
  else       
    return (
    <Provider >
      <View style={containerStyle}>
        <NavigationContainer linking= {linking} >
          <Appbar style= {{backgroundColor: "lightgray"}}>
            <Appbar.Content titleStyle={styles.heading2} subtitleStyle={styles.smallBody} title={"Goods2Give "} subtitle={strings[language].subTitle} />
            <Appbar.Action icon= {Lang("EN")} onPress={ () => {setLanguage('EN')}} />
            <Appbar.Action icon= {Lang("DE")} onPress={ () => {setLanguage('DE')}} />

          </Appbar>
          <TabManager language={language}/>
        </NavigationContainer>
        <Toast ref={(ref) => global['toast'] = ref} />
      </View>
    </Provider>
  );
}

