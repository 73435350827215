export const strings = {
	EN :{
		notFound : 'No result found withing 25 kilometres of this postal code...',
		subTitle: 'Swiss directory of non-monetary donations',
		smallIntro: 'Goods2Give is a directory of Swiss charitable organisations that accept non-monetary donations. You can choose a postal code and the type of item you want to donate, to see a list of nearby charitable organisation accepting your donation.',
		plzLabel: 'Please provide a postal code (PLZ)',
		itemTypesLabel: 'What items would you like to give away?',
		buttonLabel: 'Show Nearby Charities',
		thanks: 'Thank you for sending the information',
		charityInfoPlaceholder: 'Enter charity information here'

	},
	DE: {
		notFound : 'Keine Ergebnisse in der Nähe dieser PLZ gefunden...',
		subTitle: 'Schweizer Direktorium der nicht-monetären Spenden',
		smallIntro: 'Goods2Give ist ein Verzeichnis der Schweizer Wohltätigkeitsorganisationen, die nicht-monetäre Zuwendungen annehmen. Sie können eine Postleitzahl und die Art des Gegenstands, den Sie spenden möchten auswählen, um eine Liste der in der Nähe befindlichen gemeinnützigen Organisationen anzuzeigen, die Ihre Spende annehmen können.',
		plzLabel: 'Bitte geben Sie eine PLZ ein',
		itemTypesLabel: 'Was für Arte der Wäre möchten Sie spenden?',
		buttonLabel: 'Find Hilfsorganisationen in der Nähe',
		thanks: 'Danke vielmals für die Informationen',
		charityInfoPlaceholder: 'Bitte geben Sie Informationen hier ein'


	}
}